import {
  useCurrentCustomer,
  useGetCustomerIconsQuery,
} from '@collection-platform-frontend/api';
import { useDarkModeContext } from '@collection-platform-frontend/shared';
import {
  BackButton,
  Button,
  Container,
  DarkModeToggle,
  IPageLayout,
  Typography,
} from '@collection-platform-frontend/ui';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { toast } from 'react-hot-toast';

import { AccountForm, ProfileForm } from '../../components/user';

type PageRenderProps = {
  useEmailUpdator?: boolean;
  useThemeChanger?: boolean;
  PageLayout: IPageLayout;
};

export const SettingPageRender = ({
  useEmailUpdator = false,
  useThemeChanger = false,
  PageLayout,
}: PageRenderProps) => {
  const SettingPage = () => {
    const { isReady, back } = useRouter();

    const { changeMode, isDarkMode } = useDarkModeContext();

    const { authUser, customer, refetchCustomer } = useCurrentCustomer();
    const myCustomer = customer?.myCustomer;

    const [{ data: customerIconsData }] = useGetCustomerIconsQuery({
      pause: !isReady || !authUser.id,
    });

    const onChange = useCallback(
      (value: boolean) => {
        changeMode(value);
      },
      [changeMode],
    );

    const onLogout = useCallback(() => {
      authUser.signOut();
      toast.remove();
    }, [authUser]);

    const onBack = useCallback(() => {
      back();
    }, [back]);

    const onUpdateProfile = useCallback(() => {
      refetchCustomer();
    }, [refetchCustomer]);

    return (
      <PageLayout authUser={authUser} user={myCustomer}>
        {myCustomer && (
          <Container>
            <BackButton className="py-4" onClick={onBack} />
            <div className="flex flex-col pb-8 gap-y-8">
              <ProfileForm
                icons={customerIconsData?.customerIcons}
                formData={myCustomer}
                onUpdate={onUpdateProfile}
              />
              {useEmailUpdator && <AccountForm formData={myCustomer} />}
              {useThemeChanger && (
                <div className="p-8 shadow-3xl rounded-3xl bg-wallet-light-container dark:bg-wallet-container dark:shadow-[#111111]">
                  <Typography className="pb-6" variant="h2">
                    アプリ設定
                  </Typography>
                  <div className="flex items-center justify-between">
                    <Typography variant="h3">テーマ</Typography>
                    <DarkModeToggle toggle={isDarkMode} onChange={onChange} />
                  </div>
                </div>
              )}
            </div>
            <div className="flex justify-center">
              <Button className="w-[200px]" onClick={onLogout}>
                ログアウト
              </Button>
            </div>
          </Container>
        )}
      </PageLayout>
    );
  };

  return SettingPage;
};
