import { cn } from '@collection-platform-frontend/shared';
import { Typography } from '@collection-platform-frontend/ui';
import { FC, PropsWithChildren } from 'react';

type ButtonAreaProps = PropsWithChildren<{
  className?: string;
  description?: string;
}>;

export const ButtonArea: FC<ButtonAreaProps> = ({
  className,
  description,
  children,
}) => {
  return (
    <div className={cn('flex flex-col items-center justify-start', className)}>
      <Typography variant="body2" className="mb-4 text-wallet-secondary">
        {description}
      </Typography>

      {children}
    </div>
  );
};
