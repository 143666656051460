import { cn } from '@collection-platform-frontend/shared';
import { Image } from '@collection-platform-frontend/ui';
import { FC } from 'react';

type Props = {
  image?: string | null;
  grayscale?: boolean;
};

export const Badge: FC<Props> = ({ image, grayscale = false }) => {
  if (!image) {
    return null;
  }

  return (
    <Image
      className={cn('w-[64px] h-[64px] rounded-full', {
        'grayscale brightness-75': grayscale,
      })}
      objectFit="cover"
      layout="fill"
      src={image}
      alt="campaign_badge_image"
    />
  );
};
