import { useCampaignQuiz } from '@collection-platform-frontend/api';
import { cn } from '@collection-platform-frontend/shared';
import {
  Button,
  Image,
  ProgressBar,
  Typography,
} from '@collection-platform-frontend/ui';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { FC, useCallback, useState } from 'react';

import BaseModal, { BaseModalProps } from './base';

type QuizProgressBarProps = {
  total: number;
  progress: number;
  className?: string;
};

const QuizProgressBar: FC<QuizProgressBarProps> = ({
  total,
  progress,
  className,
}) => {
  return (
    <div className="flex flex-row items-center w-full my-4">
      <ProgressBar
        className={className}
        progress={progress}
        total={total}
        color={'#F8C205'}
        minPx={30}
      />
    </div>
  );
};

type QuizBodyProps = {
  text: string;
  imageUrl?: string;
};

const QuizBody: FC<QuizBodyProps> = ({ text, imageUrl }) => {
  return (
    <div className="flex flex-col items-center w-full my-4">
      {imageUrl ? (
        <>
          <Image
            className="w-full mb-4 aspect-quiz-image"
            layout="fill"
            src={imageUrl}
          />
          <Typography className="mx-4 text-wallet-thirdly">{text}</Typography>
        </>
      ) : (
        <Typography className="mx-4 my-10">{text}</Typography>
      )}
    </div>
  );
};

type QuizOptionStatus = 'selectable' | 'correct' | 'incorrect';

type QuizOptionBadgeProps = {
  status: QuizOptionStatus;
};

const QuizOptionsBadge: FC<QuizOptionBadgeProps> = ({ status }) => {
  const styles = {
    base: 'w-9 h-9',
    correct: 'text-wallet-success',
    incorrect: 'text-wallet-error',
    selectable: 'text-wallet-thirdly',
  };
  return (
    <>
      {status === 'correct' && (
        <CheckCircleIcon className={cn(styles.base, styles.correct)} />
      )}
      {status === 'incorrect' && (
        <XCircleIcon className={cn(styles.base, styles.incorrect)} />
      )}
      {status === 'selectable' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className={cn(styles.base, styles.selectable)}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      )}
    </>
  );
};

type QuizOptionWithSelected = {
  id: string;
  text: string;
  isCorrect: boolean;
  selected: boolean;
};

type QuizOptionsProps = {
  options: QuizOptionWithSelected[];
  disabled: boolean;
  corrected: boolean;
  onSelect: (idx: number) => void;
};

const QuizOptions: FC<QuizOptionsProps> = ({
  options,
  disabled,
  corrected,
  onSelect,
}) => {
  const styles = {
    base: 'mb-4 border-2 rounded-xl shadow-5xl text-start p-2 cursor-pointer w-full',
    selectable:
      'border-wallet-thirdly disabled:border-wallet-thirdly disabled:bg-white',
    correct: 'bg-wallet-success/20 border-wallet-success',
    incorrect: 'bg-wallet-error/20 border-wallet-error',
    disabled: 'pointer-events-none',
  };

  return (
    <>
      {options.map((option, idx) => (
        <div
          key={option.id}
          className={cn(
            styles.base,
            option.selected
              ? option.isCorrect
                ? styles.correct
                : styles.incorrect
              : styles.selectable,
            (disabled || option.selected || corrected) && styles.disabled,
          )}
          onClick={() => onSelect(idx)}
        >
          <div className="flex flex-row items-center space-x-4">
            <div>
              <QuizOptionsBadge
                status={
                  option.selected
                    ? option.isCorrect
                      ? 'correct'
                      : 'incorrect'
                    : 'selectable'
                }
              />
            </div>
            <Typography variant="h4" className="text-lg">
              {option.text}
            </Typography>
          </div>
        </div>
      ))}
    </>
  );
};

type QuizAnswerProps = {
  show: boolean;
  correct: boolean;
  commentary?: string;
};

const QuizAnswer: FC<QuizAnswerProps> = ({ show, correct, commentary }) => {
  if (!show) {
    return null;
  }

  return correct ? (
    <div className="mx-4 my-8 text-sm">
      <div>
        <Typography>
          正解
          <span role="img" aria-label="correct-icon">
            🎉
          </span>
        </Typography>
      </div>
      {commentary && (
        <div className="flex flex-col mt-4 justify-content-cetner">
          <Typography className="text-wallet-light-secondary">
            {commentary}
          </Typography>
        </div>
      )}
    </div>
  ) : (
    <div className="mx-4 my-4 text-sm">
      <Typography>
        不正解
        <span role="img" aria-label="incorrect-icon">
          😵
        </span>
      </Typography>
    </div>
  );
};

type NextButtonProps = {
  achievementPointName: string;
  onNext: () => void;
  hasNextQuiz: boolean;
  disabled: boolean;
  loading: boolean;
};

const NextButton: FC<NextButtonProps> = ({
  achievementPointName,
  onNext,
  hasNextQuiz,
  disabled,
  loading,
}) => {
  return (
    <Button
      className="px-8 max-w-fit"
      primary
      onClick={onNext}
      disabled={disabled}
      loading={loading}
    >
      {hasNextQuiz ? '次の問題へ' : `${achievementPointName}を獲得する`}
    </Button>
  );
};

type QuizModalProps = BaseModalProps & {
  achievementPointName: string;
  total: number;
  progress: number;
  loading: boolean;
  quizId: string;
  onAchieve: (optionId: string) => void;
  onNext: () => void;
  onFinish: () => void;
};

export const QuizModal: FC<QuizModalProps> = ({
  achievementPointName,
  show,
  onClose,
  total,
  progress,
  loading: loadingOuter,
  quizId,
  onAchieve,
  onNext,
  onFinish,
}) => {
  const [corrected, setCorrected] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);

  const close = useCallback(() => {
    setCorrected(false);
    setShowAnswer(false);
    onClose?.();
  }, [onClose]);
  const onCorrect = useCallback(
    (optionId: string) => {
      onAchieve(optionId);
      setCorrected(true);
      setShowAnswer(true);
    },
    [onAchieve],
  );
  const onIncorrect = useCallback(() => {
    setCorrected(false);
    setShowAnswer(true);
  }, []);

  const { quiz, options, onSelectOption, fetchQuiz, fetching } =
    useCampaignQuiz(quizId, onCorrect, onIncorrect);
  const loading = fetching || loadingOuter;
  const hasNextQuiz = progress < total;
  const achieved = corrected;

  const onClickNext = useCallback(() => {
    setCorrected(false);
    setShowAnswer(false);
    if (hasNextQuiz) {
      fetchQuiz().then(onNext);
    } else {
      onFinish();
    }
  }, [fetchQuiz, hasNextQuiz, onFinish, onNext]);

  return quiz ? (
    <BaseModal show={show} onClose={!loading ? close : undefined}>
      <QuizProgressBar total={total} progress={progress} />
      <QuizBody text={quiz?.text} imageUrl={quiz?.imageAsset?.url} />
      <QuizOptions
        options={options}
        disabled={achieved}
        corrected={corrected}
        onSelect={onSelectOption}
      />
      <QuizAnswer
        show={showAnswer}
        correct={corrected}
        commentary={quiz?.commentary}
      />
      <NextButton
        achievementPointName={achievementPointName}
        hasNextQuiz={hasNextQuiz}
        disabled={!achieved}
        onNext={onClickNext}
        loading={loading}
      />
    </BaseModal>
  ) : null;
};
