import { useInterval } from '@collection-platform-frontend/shared';
import { Typography } from '@collection-platform-frontend/ui';
import { FC, useEffect, useState } from 'react';
import { useReward } from 'react-rewards';

import { BadgeCard } from '../badge-card';
import { BaseModal } from './base';

type Props = {
  show: boolean;
  pointUnit: string;
  achievementIconName: string;
  achievementIcon?: {
    id: string;
    title: string;
    description: string;
    image?: string | null;
    pointAmount: number;
    achievedAt?: Date | null;
  };
  onClose: () => void;
};

export const BadgeAwardModal: FC<Props> = ({
  show,
  pointUnit,
  achievementIconName,
  achievementIcon: currentIcon,
  onClose,
}) => {
  // NOTE: モーダルを閉じるアニメーション時にコンテンツを表示し続けるために一時的にデータを残しておく。
  const [previousIcon, setPreviousIcon] = useState<Props['achievementIcon']>();
  useEffect(() => {
    if (currentIcon) setPreviousIcon(currentIcon);
  }, [previousIcon, currentIcon]);

  const achievementIcon = currentIcon || previousIcon;

  const { reward: rewardLeft, isAnimating: isAnimatingLeft } = useReward(
    'rewardLeft',
    'confetti',
    {
      angle: 70,
      lifetime: 240,
      spread: 90,
      elementCount: 100,
      position: 'fixed',
    },
  );
  const { reward: rewardRight, isAnimating: isAnimatingRight } = useReward(
    'rewardRight',
    'confetti',
    {
      angle: 100,
      lifetime: 240,
      spread: 90,
      elementCount: 100,
      position: 'fixed',
    },
  );

  useInterval(() => {
    if (!show) {
      return;
    }

    if (!isAnimatingRight || !isAnimatingLeft) {
      rewardLeft();
      rewardRight();
    }
  });

  return (
    <BaseModal show={show} onClose={onClose}>
      {achievementIcon && (
        <>
          <div className="mb-6">
            <Typography variant="h2" className="mb-2">
              {`${achievementIconName}獲得`}
              <br />
              おめでとうございます！
            </Typography>
            <Typography variant="body2" className="text-wallet-light-secondary">
              {`${pointUnit}が達成数に到達したので、新たな${achievementIconName}を手に入れました。`}
            </Typography>
          </div>

          <BadgeCard
            key={achievementIcon.id}
            id={achievementIcon.id}
            className="w-full mb-5"
            title={achievementIcon.title}
            description={achievementIcon.description}
            image={achievementIcon.image}
            pointAmount={achievementIcon.pointAmount}
            pointUnit={pointUnit}
            achievedAt={achievementIcon.achievedAt}
          />
        </>
      )}
      <div className="relative w-full">
        <span id="rewardLeft" className="absolute bottom-0 left-0" />
        <span id="rewardRight" className="absolute bottom-0 right-0" />
      </div>
    </BaseModal>
  );
};
