import { Typography } from '@collection-platform-frontend/ui';
import { FC } from 'react';

import { PointIcon } from './icons/point';

type Props = {
  amount?: number | null;
  unit: string;
};

export const PointColumn: FC<Props> = ({ amount, unit }) => {
  return (
    <div className="flex flex-row items-center justify-start flex-nowrap gap-x-1">
      <PointIcon className="w-[15px] h-[15px]" />
      <Typography variant="body2">
        {amount?.toLocaleString()} {unit}
      </Typography>
    </div>
  );
};
