import {
  getMediaType,
  useGetMyNftDetailQuery,
} from '@collection-platform-frontend/api';
import { Button, MediaSlider } from '@collection-platform-frontend/ui';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useWindowSize } from '@react-hook/window-size';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

type RenderOptions = {
  currentApplicationId?: string;
  parentPagePath?: string;
};

export const ViewerPageRender = ({
  currentApplicationId,
  parentPagePath = '/collection',
}: RenderOptions = {}) => {
  const ViewerPage = () => {
    const { push, replace, query, isReady } = useRouter();
    const id = query['id'] as string;

    const [{ data, fetching: nftFetching }] = useGetMyNftDetailQuery({
      pause: !isReady || !id,
      variables: {
        id,
        applicationId: currentApplicationId,
      },
      requestPolicy: 'network-only',
    });

    const nfts = data?.myNfts.edges?.map(({ node }) => node);
    const currentNft = nfts?.[0];
    const sliderSources = useMemo(() => {
      const contents = currentNft?.item?.unlockableContents;
      if (!contents) {
        return [];
      }

      return contents.map((content) => {
        const mimeType = content.privateAsset?.mimeType;
        const contentType = getMediaType(mimeType);
        return {
          contentType,
          url: content.privateAsset?.url,
          thumbnailUrl: content.thumbnailPrivateAsset?.url,
        };
      });
    }, [currentNft]);

    useEffect(() => {
      const contentsExist = data && sliderSources.length > 0;
      if (nftFetching || contentsExist) {
        return;
      }

      replace(parentPagePath);
    }, [nftFetching, data, sliderSources, replace]);

    const [size, setSize] = useState<{ width: number; height: number }>();
    const [width, height] = useWindowSize();

    useEffect(() => {
      if (width === 0 || height === 0) {
        return;
      }

      setSize({
        width,
        height,
      });
    }, [width, height]);

    const onBackToItem = () => {
      push(`${parentPagePath}/${id}`);
    };

    return (
      <div className="relative w-full h-screen bg-zinc-900">
        {size && sliderSources.length > 0 && (
          <MediaSlider
            slideHeight={size.height}
            slideWidth={size.width}
            showThumbnails
            sources={sliderSources}
          />
        )}
        <Button
          className="fixed p-2 !bg-gray-500 !border-gray-500 rounded-full top-2 left-2 !min-w-fit !w-fit !h-fit hidden sm:block"
          onClick={onBackToItem}
        >
          <ChevronLeftIcon className="w-4 h-4 text-zinc-100" />
        </Button>
      </div>
    );
  };

  return ViewerPage;
};
