import { cn, format, to } from '@collection-platform-frontend/shared';
import { Button, Image, Typography } from '@collection-platform-frontend/ui';
import { LockClosedIcon } from '@heroicons/react/24/solid';

import { PointColumn } from './point-column';

export type ExchangeItemCardProps = {
  className?: string;
  title: string;
  image?: string | null;
  pointAmount: number;
  pointUnit: string;
  startAt: Date;
  endAt: Date;
  exchangedAt?: Date;
  onAction?: () => void;
};

export const ExchangeItemCard: React.FC<ExchangeItemCardProps> = ({
  className,
  title,
  image,
  pointAmount,
  pointUnit,
  startAt,
  endAt,
  exchangedAt,
  onAction,
}: ExchangeItemCardProps) => {
  const now = new Date();
  const isBeforeStart = now < startAt;
  const isAfterEnd = endAt < now;
  const timeLeft = to(endAt, now, { locale: 'ja', withoutSuffix: true });

  return (
    <div
      className={cn(
        'flex flex-col rounded-3xl overflow-hidden shadow-xl dark:bg-wallet-container',
        className,
      )}
    >
      {image && (
        <Image
          className="w-full h-[284px] sm:h-[284px]"
          objectFit="cover"
          layout="fill"
          src={image}
          alt="campaign_banner_image"
        />
      )}
      <div className="flex flex-col px-6 py-3 space-y-4">
        <div className="flex flex-col space-y-1">
          <Typography variant="h3">{title}</Typography>
          <div className="flex flex-row items-center gap-x-1">
            <Typography
              variant="caption"
              className="text-wallet-light-secondary"
            >
              必要ポイント：
            </Typography>
            <PointColumn amount={pointAmount} unit={pointUnit} />
          </div>
          {!exchangedAt && (
            <div className="flex flex-row items-center gap-x-1">
              <Typography
                variant="caption"
                className="text-wallet-light-secondary"
              >
                期間：
                {isAfterEnd
                  ? '終了しました'
                  : isBeforeStart
                  ? `${format(startAt, 'YYYY年M月D日')}に公開予定`
                  : `あと${timeLeft}`}
              </Typography>
            </div>
          )}
        </div>
        <div className="flex flex-col pb-2 space-y-1">
          {!exchangedAt && !isAfterEnd && (
            <div className="flex space-x-2">
              <Button primary slim disabled={isBeforeStart} onClick={onAction}>
                {isBeforeStart ? (
                  <LockClosedIcon className="w-5 h-5" />
                ) : (
                  '交換する'
                )}
              </Button>
            </div>
          )}
          {!isAfterEnd && (
            <Typography
              className="text-wallet-light-secondary"
              variant="caption"
            >
              {exchangedAt
                ? `獲得日: ${format(exchangedAt, 'YYYY年M月D日')}`
                : 'おひとり様一点限り'}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};
