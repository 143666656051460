import {
  Button,
  MaskInput,
  Typography,
} from '@collection-platform-frontend/ui';
import { FC } from 'react';

import BaseModal from './base';

type Props = {
  codeLength: number;
  show: boolean;
  pointUnit: string;
  value: string;
  loading?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  onChange: (achievementCode: string) => void;
  onSubmit: () => void;
  onClose: () => void;
};

export const AchievementCodeFormModal: FC<Props> = ({
  codeLength,
  show,
  pointUnit,
  value,
  loading,
  errorMessage,
  disabled,
  onChange,
  onSubmit,
  onClose,
}) => {
  return (
    <BaseModal show={show} onClose={onClose}>
      <div className="mb-6">
        <Typography variant="h2" className="mb-2">
          ミッションコードを入力する
        </Typography>
        <Typography variant="body2" className="text-wallet-light-secondary">
          {`ミッションコードを入力して${pointUnit}を手に入れましょう`}
        </Typography>
      </div>

      <div className="flex flex-col items-center w-full mb-6">
        <div className="flex flex-col items-start">
          <Typography variant="h3" className="mb-1">
            ミッションコードを入力
          </Typography>

          <div className="min-w-[250px] text-left">
            <MaskInput
              disabled={disabled}
              minLength={codeLength}
              maxLength={codeLength + 3}
              placeholder={'____-____-____-____'}
              required
              // type="email"
              // inputMode="email"
              pattern="^[0-9a-zA-Z]+$"
              forceUppercase={true}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              errorMessage={errorMessage}
              onChangeValue={onChange}
              defaultValue={value}
            />
          </div>
          {!errorMessage && (
            <Typography variant="caption" className="pt-1">
              ハイフン(-)抜きの半角英数字を入力してください
            </Typography>
          )}
        </div>
      </div>

      <div className="flex flex-col items-center w-full mb-5">
        <Button
          className="max-w-[178px]"
          disabled={!value || value?.length < codeLength}
          primary
          onClick={onSubmit}
          loading={loading}
        >
          コードを使用する
        </Button>
      </div>
    </BaseModal>
  );
};
