import {
  useCampaignQuizzes,
  useRecordAchieveQuiz,
} from '@collection-platform-frontend/api';
import { FC, useCallback, useState } from 'react';

import {
  ModalState,
  QuizChallengeModalState,
  QuizModal,
  QuizModalState,
  QuizzesChallengeModal,
} from './modals';

type Props = {
  modalState: ModalState;
  onAchieveMission: (missionId: string) => void;
  onCloseChallengeModal: () => void;
  onCloseQuizModal: () => void;
  onOpenQuizModal: (missionId: string, quizId: string) => void;
};

export const QuizModals: FC<Props> = ({
  modalState,
  onCloseChallengeModal,
  onCloseQuizModal,
  onOpenQuizModal,
  onAchieveMission,
}) => {
  const challengeModalProps =
    modalState?.type === 'quiz-challenge'
      ? (modalState as QuizChallengeModalState).props
      : undefined;
  const quizModalProps =
    modalState?.type === 'quiz'
      ? (modalState as QuizModalState).props
      : undefined;
  const missionId = (challengeModalProps?.mission?.id ||
    quizModalProps?.mission?.id) as string;

  const { progress, total, quizzes, fetching } = useCampaignQuizzes(missionId);
  const [quizId, setQuizId] = useState(quizzes[progress]?.id);
  const { recordAchieveCampaignQuiz } = useRecordAchieveQuiz();

  const onAchieveQuiz = useCallback(
    (optionId: string) => {
      if (quizId) {
        recordAchieveCampaignQuiz({
          id: quizId,
          optionId,
        });
      }
    },
    [quizId, recordAchieveCampaignQuiz],
  );

  const onFinishQuiz = useCallback(() => {
    onAchieveMission(missionId);
  }, [missionId, onAchieveMission]);

  const onNext = useCallback(() => {
    if (progress >= total) {
      onAchieveMission(missionId);
    } else {
      setQuizId(quizzes[progress].id);
      if (modalState?.type !== 'quiz') {
        onOpenQuizModal(missionId, quizzes[progress].id);
      }
    }
  }, [
    missionId,
    modalState,
    onAchieveMission,
    onOpenQuizModal,
    progress,
    quizzes,
    total,
  ]);

  return (
    <>
      {challengeModalProps && (
        <QuizzesChallengeModal
          show={true}
          progress={progress}
          total={total}
          quizzes={quizzes}
          onNext={onNext}
          onClose={onCloseChallengeModal}
          loading={fetching}
        />
      )}

      {quizModalProps && (
        <QuizModal
          show={true}
          total={total}
          progress={progress}
          achievementPointName={quizModalProps.achievementPointName}
          quizId={quizId}
          onAchieve={onAchieveQuiz}
          onFinish={onFinishQuiz}
          onNext={onNext}
          onClose={onCloseQuizModal}
          loading={fetching}
        />
      )}
    </>
  );
};
