import {
  ArticleSection,
  ArticleSectionProps,
} from '@collection-platform-frontend/ui';

const terms: ArticleSectionProps[] = [
  {
    title: '第1条 総則',
    contents: [
      '利用規約（以下、「本規約」といいます。）においては、Anique株式会社（以下「当社」といいます。）が提供するAnique Walletをご利用いただくユーザー（以下「ユーザー」といいます。）の遵守事項およびAnique Wallet利用に関する当社とユーザーとの間の関係が定められています。ユーザーは、Anique Walletを利用される際には、本規約の全文を十分理解した上で、ご同意いただく必要があります。本規約は、ユーザーと当社との間の完全な法的合意を構成するもので、Anique Walletへのアクセスおよび使用を管理し、過去または現在の口頭または書面によるAnique Walletへのアクセスまたは使用に関する当事者間の契約を完全に置き換えるものとします。',
      '当社は、以下の各号のいずれかに該当する場合は、民法第548条の4の規定に基づき本規約を随時変更できます。本規約が変更された後の前項の合意は、変更後の本規約が適用されます。',
      [
        '本規約の変更が、ユーザーの一般の利益に適合するとき',
        '本規約の変更が、前項の合意をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性及びその内容その他の変更に係る事情に照らして合理的なものであるとき',
      ],
      '当社は、本規約の変更を行う場合は、変更後の本規約の効力発生時期を定め、効力発生時期の2週間前までに、変更後の本規約の内容及び効力発生時期をユーザーに通知、本サービス上への表示その他当社所定の方法によりユーザーに周知します。',
      '前二項の規定にかかわらず、前項の本規約の変更の周知後にユーザーがAniqueを利用した場合又は当社所定の期間内にユーザーが解約の手続を取らなかった場合、当該ユーザーは本規約の変更に同意したものとします。',
    ],
  },
  {
    title: '第2条 Anique Walletについて',
    contents: [
      'ユーザーはAnique Wallet上で、静止画・動画・3DCG等の作品（以下「作品等」といいます。）を基に発行されたトークン（以下「コンテンツ」といいます。）を保有、売買することができ、Webサイト（以下、「本サイト」といいます。）において表示させることができます。Aniqueに係るスマートコントラクト（以下、「本スマートコントラクト」といいます。）および本サイトは、本規約において「本サービス」と総称されます。本サービスを使用すると、ユーザーは作品等の閲覧、付帯する特典へのアクセス、当社から提供される範囲内で作品等を利用することができます。なお、コンテンツは、一つ一つのデータが固有の情報でかつ大量の種類が存在するため、一つ一つが代替不可能な固有の電磁的アセットであり、資金決済法上の「暗号資産」に該当するものではありません。',
      '当社はコンテンツを本サービスに出品し、ユーザーに販売するとともに、ユーザーに購入されたコンテンツのその後の取引状況に応じ、当社が定める手数料を得ることができます。',
      'ユーザーが会社または他の法人に代わって本規約に同意する場合、ユーザーがその企業そのものであるとみなして、本規約に同意する法的権限を有することを表明します。ユーザーがこのような権限を持っていない場合、または本規約のすべてに同意しない場合、本サービスにアクセスまたは使用することはできません。',
    ],
  },
  {
    title: '第3条 サービスの利用',
    contents: [
      '本サービスのアカウントを作成することで、ウォレットが当社のサーバーで作成され、ユーザーに割り当てられます。',
      '本サービスで実行されるトランザクションは、ブロックチェーン経由で管理および確認されます。ユーザーは、本サービス上のトランザクションを行う度に自身のブロックチェーンアドレスが一般公開されることにつきあらかじめ同意します。',
      '当社は、Webブラウザ、ウォレット、ブロックチェーンネットワーク、またはユーザーが本サービスのさまざまな機能を使用できるようにするためのソフトウェアの不具合について一切責任を負わず、また、ユーザーのトランザクションおよびユーザー間のやり取りの結果として被る可能性のある損害についても当社は一切責任を負わないものとします。',
      'ユーザーは、本サービス用のアカウントを作成する際には、メールアドレスを含む正確な登録情報を提供する必要があります。アカウントを作成することにより、ユーザーは自分に関する正確かつ最新の情報を提供し、必要に応じて速やかに更新することに同意します。',
      'ユーザーのアカウントとウォレットのセキュリティに関し、ユーザーは自らの管理責任により、パスワードやアカウントを不正使用されないよう厳格に管理するものとします。ユーザーはいかなる場合も、パスワードやアカウントを第三者に開示、貸与することはできません。当社は、パスワードやアカウントの不正利用によってユーザーに生じた損害について一切の責任を負いません。当社は、パスワードやアカウントの認証を行なった後に行われた本サービスの利用行為については、すべてユーザーが行ったものとみなします。',
      '本サービスで実施されるキャンペーンにおいて、ユーザーが自身の創作物を使用して参加した場合、ユーザーは、当社に対して、本サービスの運営に必要な一切の利用を、非独占的かつ無償で許諾したものとします。なお、ユーザーが自身の創作物に関して有する著作権は、ユーザーに留保されます。',
    ],
  },
  {
    title: '第4条 手数料と支払い',
    contents: [
      '当社は、ブロックチェーン上で発生したトランザクションを取り消すことはできません。当社は、ユーザーが本サービスを介して、あるいはブロックチェーンネットワークまたはウォレット経由で行動したトランザクションの結果として生じうる請求または損害について、当社は、ユーザーまたは第三者にいかなる責任も負わないものとします。',
      'ユーザーは本サービスを介して別のユーザーとトランザクションを行う度に、その取引の合計金額の一定割合の手数料を当社が徴収することに同意するものとします。',
      'ユーザーは、当社との間で本サービスの使用に関連して、政府当局によって請求または課されるいかなる付加価値税、関税、その他の税金の支払いはユーザーが単独で責任を負うことにつき同意するものとします。',
    ],
  },
  {
    title: '第5条 コンテンツにかかる権利利',
    contents: [
      'ユーザーは、本サービス上で購入する作品等の権利につき、以下の内容を受け入れ了承します。',
      'ユーザーが本サービス上で当社から出品されたコンテンツを購入し、その決済が本サービス上で完了した後、当社はユーザーアドレスに当該コンテンツを送付します。この時点で、当該コンテンツを購入したユーザーは、作品ごとに定める範囲内で利用する権利を得るものとします。',
      'ユーザーがコンテンツを、本サービス外のプラットフォーム等（ただし当社が提携契約を締結している事業者は除きます）において第三者との間で譲渡することは禁じられております。当社は、本サービス外での取引および当該取引から発生するすべての事象につき、一切の責任を負わないものとします。',
      'ユーザーは、本サービスで販売するコンテンツに関して、本規約上許諾された権利のみを行使できるものとします。',
      'ユーザーは私的利用の範囲に限り、作品等を本サービス上で利用できます。ユーザーは原作者の明示的な許諾なしに、画像データおよび額装絵等の特典の発行、再発行、配信、販売、再販、修正、二次創作やその配布、販売等の商用利用を含む、私的利用にとどまらない利用はできません。また、コピー、ダウンロード、ストリーミング、キャプチャ、アーカイブやアップロードについても、私的利用にとどまらない利用はできません。',
      '当社はコンテンツを売り出す際に、抽選を用いることがあります。その場合、購入意思を表示し、かつ、当選の条件を満たしたユーザーを対象に抽選を行い、当選者に購入する権利を付与します。',
      'コンテンツ購入の決済が完了し、当社からコンテンツを送付した後に、チャージバック等決済にかかる不正行為、その他本利用規約への違反等が発見された場合、当社は送付したトークンを失効する処理を行うことがあります。',
    ],
  },
  {
    title: '第6条 本サービスの終了',
    contents: [
      'ユーザーは、当社に本サービスの利用を終了する旨を申し出ることができ、当社の判断により認められた場合には本サービスの利用を終了することができます。ユーザーは、本サービスの利用を終了してアカウントを閉鎖する場合、一切の払い戻しを受けることができないことにつき同意するものとします。その時点でユーザーが本アカウント上に所持しているコンテンツはすべて失われます。それらのコンテンツは無償かつ自動的に当社に移転されます。',
      'ユーザーは、当社が独自の裁量により、いかなる理由を問わず、本規約を終了し、本サービスのアカウントを一時停止または終了することがあることにつき同意するものとします。ユーザーは、本サービスへのアクセスの停止または終了が予告なしに行われる可能性があり、そのような一時停止または終了に関して、当社はユーザーまたは第三者に対し、一切の責任を負わないことにつき同意するものとします。',
      'ユーザーに本規約の違反または疑わしい不正または違法行為の疑いが認められる場合、当社は、ユーザーのアカウントを閉鎖させたり、本サービスへのアクセスまたは利用を一時停止または終了させることができます。',
    ],
  },
  {
    title: '第7条 リスクの想定',
    contents: [
      'ユーザーは以下に掲げるリスクにつき、内容を受け入れ了承します。',
      'ブロックチェーン資産の価格は大きく変動します。他のデジタル資産の価格の変動は、ユーザーが保有するコンテンツの価値に重大かつ悪影響を及ぼす可能性があり、価格の大幅な変動の可能性があります。当社は、コンテンツの購入者が当該価格変動によって生じる損害につき一切の責任を負わないものとします。',
      'コンテンツに関連したトランザクションに税の支払いを義務付けられるかを判断する責任は、ユーザーのみが有します。 当社は、本サービス、本サイト、または本スマートコントラクト上のトランザクションに適用される税負担の決定に関して一切の責任を負いません。',
      'インターネットベースのトークンの使用には、ハードウェア、ソフトウェア、インターネット接続のリスク、悪意のあるソフトウェア導入のリスク、および第三者がユーザーのトークンウォレットに保管されている情報に不正にアクセスするリスクがあります。ユーザーは、ブロックチェーンネットワークの使用中に発生する可能性のある通信障害、中断、エラー、遅延について当社が一切の責任を負わないことにつき同意するものとします。',
      'ブロックチェーン技術、暗号化通貨、およびトークンを管理する規制体制は不確実であり、新しい規制やポリシーが本サービスのエコシステムの開発に重大な悪影響を及ぼし、コンテンツの潜在的な有用性や価値に悪影響を与える可能性があります。',
      '使用するブロックチェーンのアップグレード、ハードフォーク、またはトランザクションの確認方法の変更は、本サービスに対して意図しない影響を与える可能性があります。',
    ],
  },
  {
    title: '第8条 免責事項',
    contents: [
      'ユーザーは、本サービスを利用することが、ユーザーに適用のある法令等に違反するか否かを自己の責任と費用に基づいて調査するものとします。当社は、ユーザーによる本サービスの利用が、ユーザーに適用のある法令等に適合することを何ら保証するものではありません。',
      '当社は、本サービスを構成するソフトウェアにバグ等の瑕疵のないことや、本サービスが契約の特定の利用目的に合致することを保証するものではありません。また、当社は、端末機器において他のソフトウェア等が使用ないし併用された場合の、本サービスの正常な動作を保証するものではありません。',
      '本サービスに関連してユーザーと他のユーザーまたは第三者との間において生じた取引、連絡、紛争等については、ユーザーの責任において処理および解決するものとし、当社はかかる事項について一切責任を負いません。',
      '当社は、セキュリティのために必要な対策を講じておりますが、本サービスにおける完全な安全性を保証するものではありません。',
      '当社は、当社がブロックチェーンネットワークまたはウォレットを使用した結果生じる、（a）パスワードやウォレットのシードフレーズ等認証に必要な情報の滅失や本スマートコントラクト、あるいはその他のトランザクションにおけるエラー（b）サーバーの故障またはデータの損失（c）ウォレットに関するデータの破損（d）本サービス、ブロックチェーンネットワーク、またはウォレットに対するウイルス、フィッシング、ブルートフォース攻撃またはその他の攻撃の使用を含むがこれに限定されない、第三者による不正アクセスに関して生じた損害につき、一切の責任を負いません。',
      '当社は、当社による本サービスの提供の停止、中断、終了、利用不能または変更、本サービスの利用によるデータの消失または機器の故障もしくは損傷、その他本サービスに関連してユーザーが被った損害につき、賠償する責任を一切負わないものとします。',
      '当社は、暗号資産に対する法律、政令、法令、規則、命令、通達、条例、ガイドラインその他の規制（以下「法令等」といいます。）または税制の将来の変更によりユーザーに損害が発生した場合であっても、賠償する責任を一切負わないものとします。',
      '当社は、暗号資産に対する法令等または税制の将来の変更が過去に遡及したことによりユーザーに損害が発生した場合であっても、過去に遡って賠償する責任を一切負わないものとします。',
      '当社は、本規約について責任を一切負わないとする規定につき、本規約が消費者契約に該当すると認められ、かつ、当社に故意または重過失がある場合はこの限りではないものとします。',
      '当社は、本サービス上でユーザーが行ったコメント等について、不適切と判断されるものについて削除その他の適宜の対応を採ることができるものとし、当該対応によってユーザーに生じた損害につき、一切の責任を負いません。',
    ],
  },
  {
    title: '第9条 責任の制限',
    contents: [
      'ユーザーは、本規約に違反することにより、または本サービスの利用に起因または関連して当社に損害を与えた場合、当社に対し、その損害（弁護士費用を含みます。）を賠償しなければならないものとします。',
      '当社は、本サービスに起因または関連してユーザーが被った損害について、当社に故意または重過失がある場合を除き、一切負わないものとします。当社が責任を負う場合であっても、その責任は損害賠償責任に限られ、当該損害賠償責任は、ユーザーが当社に対して過去3ヶ月の間に支払った金額を上限とします。また、付随的損害、間接損害、特別損害、将来の損害および逸失利益にかかる損害については、賠償する責任を一切負わないものとします。',
      'ユーザーは、当社およびその子会社、関連会社、役員、代理人、従業員、広告主、ライセンサ、サプライヤまたはパートナーに対し、（a）ユーザーの本規約の違反、（b）本サービスの誤用、（c）本サービスへのアクセスまたは使用に関連して適用される法律、規則または規制の違反に関連するいかなる種類または性質の訴訟、賠償責任、損失、損害から賠償責任を免責することに同意します。',
    ],
  },
  {
    title: '第10条 外部サイト',
    contents: [
      '本サービスには、ユーザーの便宜のためにのみ提供される他のWebサイトやリソース（総称して、以下「外部サイト」といいます。）へのハイパーリンクが含まれる場合があります。当社は外部サイトを管理することはできません。ユーザーは、当社が、外部サイトの利用性について責任を負うものではなく、外部サイトから提供された広告、製品またはその他の資料を推奨するものではないことを認め、同意するものとします。さらに、ユーザーは、外部サイト利用の結果として、またはいかなる外部サイトに関連するユーザーの完全性、正確性、存在への信頼の結果として生じ得る損失または損害につき、当社が一切責任を負わないことを同意するものとします。',
    ],
  },
  {
    title: '第11条 知的財産権',
    contents: [
      '本サービスを構成する有形・無形の構成物（ソフトウェアプログラム、データベース、アイコン、画像、文章、マニュアル等の関連ドキュメント等を含む。）に関する著作権を含む一切の知的財産権、その他の権利は、当社または当社にライセンスを許諾した第三者に帰属します。',
    ],
  },
  {
    title: '第12条 サービスの中断・終了・変更',
    contents: [
      '当社は、以下のいずれかに該当する場合には、ユーザーに事前に通知することなく、全部または一部のユーザーによる本サービスの利用の全部または一部を停止または中断することができるものとします。',
      [
        '本サービスに係るコンピューター・システムの点検または保守作業を定期的または緊急に行う必要がある場合',
        'コンピューター、通信回線等が事故により停止した場合',
        '不可抗力により、本サービスの運営ができなくなった場合',
        '本サービス提供に必要なシステムの異常が生じた場合',
        'パスワードやアカウントの不正利用等の調査を行う場合',
        '法令等または当社規則等に基づき調査を行うことが必要であると当社が判断する場合',
        '法令、社会情勢の変化その他の事情により、本サービスの提供の継続が不可能または著しく困難であると当社が判断した場合',
        'その他当社が停止または中断を必要と判断した場合',
      ],
      '本条に基づき当社が行った措置に起因または関連してユーザーに生じた損害について、当社は、一切の責任を負いません。',
      '当社が本サービスを終了した場合、ユーザーは保有している作品等の利用権を保持し続けることはできますが、本サービスを通じた体験や取引はできません。',
      '当社は、本サービスを絶えず改善しています。ユーザーは、事前の予告なしに本サービスのの一部が変更されることがありますが、ユーザーは事前の通知なくいつでも新しい機能を追加したりサービスの一部を変更したりする可能性があることにつき、同意するものとします。',
    ],
  },
  {
    title: '第13条 禁止事項',
    contents: [
      'ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為またはそのおそれがある行為をしてはならず、以下の各号のいずれかの事由に該当する場合は、当社は事前に通知または催告することなく、当該ユーザーについて本サービスの利用を一時的に停止し、または利用を取り消す等の措置を行うことができるものとします。',
      [
        '本規約に違反する行為',
        '当社、または本サービスの他の利用者その他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為（かかる侵害を直接または間接に惹起する行為を含みます。）',
        '犯罪行為に関連する行為または公序良俗に反する行為',
        '法令または関連する規則に違反する行為',
        'コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為',
        '本サービスの利用に関する情報を改ざんする行為',
        '当社が定める一定のデータ容量以上のデータを本サービスを通じて送信する行為',
        '当社または当社が提供する各種サービスの信用・名誉等を毀損する行為',
        '他のユーザーの利用を妨害する行為',
        '本サービスを構成するハードウェアまたはソフトウェアへの不正アクセス行為、クラッキング行為その他設備等に支障を与える等の行為',
        '本サービスの提供を妨害する行為',
        '本サービスを構成するソフトウェアの解析、リバースエンジニアリングその他ソースコードを入手しようとする行為',
        '他人のユーザーIDを使用する行為またはその入手を試みる行為',
        '他のユーザーのデータを閲覧、変更、改竄する行為',
        '通常の取引に見せかけ、一人で複数アカウントを所持し出品者自身が購入したり、関係者が購入したりする行為',
        '架空の商品をクレジットカードで購入し、現金化する行為',
        '前二号の他、マネーロンダリングおよびテロ資金供与への関与並びにそれらが疑われる取引・行為',
        'その他当社が不適切と判断する行為',
      ],
      'ユーザーは、前項各号のいずれかの事由に該当した場合には、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。',
      '当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について、一切の責任を負いません。',
    ],
  },
  {
    title: '第14条 業務委託',
    contents: [
      '当社は本サービスの提供に関する業務の全部もしくは一部をユーザーの承諾なしに、第三者に委託することができます。ただし、その場合、当社は責任を持って委託先を管理するものとします。',
    ],
  },
  {
    title: '第15条 収納代行',
    contents: [
      'ユーザーは、当社に対し、本規約に従ってAnique Wallet上でコンテンツの取引を行った場合に当該契約から発生する金銭債権について、当該契約の買主である他のユーザーから弁済として資金を受け入れ、売主であるユーザーに当該資金を渡す権限を付与することに同意します。',
      '当社が前項に定める資金を買主である他のユーザーから弁済として受け入れたときに、同項の金銭債権に係る債務は消滅するものとします。',
    ],
  },
  {
    title: '第16条 秘密保持',
    contents: [
      '本規約において「秘密情報」とは、本規約または本サービスに起因または関連して、ユーザーが当社より書面、口頭、電磁的記録媒体その他の方法にかかわらず開示・提供されたまたは知り得た、当社の技術、営業、業務、財務、組織、その他のすべての事項に関する情報を意味します。ただし、以下の情報については、秘密情報から除外されるものとします。',
      [
        '当社から開示・提供がなされたときまたは知得したときに、既に一般に公知となっていたまたは既に知得していた情報',
        '当社から開示・提供または知得した後、自己の責に帰さない事由により公知となった情報',
        '開示・提供につき適法な権限のある第三者から秘密保持義務を負うことなく適法に取得した情報',
        '秘密情報によることなく自ら単独で開発した情報',
      ],
      'ユーザーは、秘密情報を本サービスの利用その他当該秘密情報が開示・提供された目的のみにおいて利用するとともに、当社の書面による承諾なく、第三者に秘密情報を開示・提供または漏洩してはならないものとします。ただし、法令、裁判所または政府機関の命令、要求または要請に基づき、秘密情報の開示を求められた場合において、当該命令、要求または要請後速やかにその旨を当社に通知したときは、当該命令、要求または要請の必要最小限の範囲において必要な第三者に開示・提供することができます。',
      'ユーザーは、当社から求められた場合にはいつでも、直ちに当社の指示に従い、秘密情報および秘密情報を記載または記録した書面その他の記録ならびに全ての複製物を返却または廃棄しなければならないものとします。',
    ],
  },
  {
    title: '第17条 不可抗力',
    contents: [
      '当社は、天災、法令・規則の制定・改廃、その他の不可抗力によって本サービスの履行が妨げられた場合には、本規約の一切の規定にかかわらず、かかる不可抗力によってユーザーに生じた損害について一切の責任を負いません。',
    ],
  },
  {
    title: '第18条 未成年',
    contents: [
      '本サービスは15歳未満の未成年を対象としていないため、ユーザーは15歳以上であることを誓約します。もしユーザーが15歳以上であるが20歳未満の場合、ユーザーは両親または保護者を伴って本規約を読み、両親または保護者が本条項を理解し内容に同意するものとします。ユーザーは両親または保護者がユーザーの代わりに本規約を理解し同意することを認めます。本規約に同意した両親または保護者は、当該未成年が本サービスを使用するための費用および法的責任を含む全責任を完全に負担することにつき同意します。',
    ],
  },
  {
    title: '第19条 プライバシーポリシー',
    contents: [
      '当社のプライバシーポリシーは、ユーザーの個人情報を収集、使用、保管、開示する方法を規定しております。ユーザーは、当社がプライバシーポリシーに従ってユーザーのデータの収集、使用、保管、開示を行うことにつき、同意するものとします。',
    ],
  },
  {
    title: '第20条 権利義務の譲渡等',
    contents: [
      'ユーザーは、当社の書面による事前の承諾なく、本規約の地位または本規約に基づく権利義務につき、第三者に対し、譲渡、移転、担保設定その他の処分をすることはできません。当社は、本サービスに関する事業を第三者に譲渡（方式を問わないものとします。）した場合には、当該譲渡に伴い本規約上の地位、本規約に基づく権利義務およびユーザーの登録情報その他の顧客情報等の全部または一部を当該譲渡の譲受人に移転することができるものとし、ユーザーは、当該譲渡につき予め同意したものとします。',
    ],
  },
  {
    title: '第21条 反社会的勢力の排除',
    contents: [
      'ユーザーは、現在かつ将来にわたり、反社会的勢力（暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標榜ゴロ、または特殊知能暴力集団等、その他これらに準ずる者をいいます。）に該当しないこと、また暴力的な要求行為、法的な責任を超えた不当な要求行為、取引に関して脅迫的な言動をし暴力を用いる行為、風説を流布し偽計を用いまたは威力を用いて信用を毀損しまたは業務を妨害する行為、その他これらに準ずる行為を行わないことを、現在および将来にわたって表明するものとします。',
    ],
  },
  {
    title: '第22条 分離可能性',
    contents: [
      '本規約のいずれかの条項の全部またはその一部が、民法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定および一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に有効であり、当社およびユーザーは、当該無効もしくは執行不能となった条項またはその一部を適法としまたは執行力を持たせるために必要な範囲で合理的に修正し、当該無効もしくは執行不能となった条項またはその部分の趣旨にかんがみ法律的・経済的に同等の効果を確保できるよう努めるものとします。',
    ],
  },
  {
    title: '第23条 協議',
    contents: [
      '本規約の解釈について両当事者間に異議、疑義が生じた場合、または本規約の定めのない事項が生じた場合、誠実に協議し、円満にその解決を図るものとします。',
    ],
  },
  {
    title: '第24条 準拠法および管轄裁判所',
    contents: [
      '本規約の準拠法は日本法とし、本規約に起因しまたは関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。',
    ],
  },
  {
    description: '---</br>2022年12月1日制定</br>Anique株式会社',
  },
];

export const Terms = () => {
  return (
    <>
      {terms.map((props, i) => {
        return <ArticleSection key={i} {...props} />;
      })}
    </>
  );
};
