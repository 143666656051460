import {
  dateFilter,
  isEPUBAsset,
  useGetMyNftDetailQuery,
} from '@collection-platform-frontend/api';
import { generateBibiUrl } from '@collection-platform-frontend/shared';
import { Button, Typography } from '@collection-platform-frontend/ui';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

type ViewerEPUBPageRenderOptions = {
  currentApplicationId?: string;
  collectionPagePath?: string;
};

export const ViewerEPUBPageRender = (options: ViewerEPUBPageRenderOptions) => {
  const ViewerEPUBPage = () => {
    const { currentApplicationId, collectionPagePath = '/collection' } =
      options;
    const { query, isReady, reload, push } = useRouter();
    const id = query['id'] as string;
    const ref = useRef<HTMLIFrameElement>();
    const [currentUrl, setCurrentUrl] = useState<string>();
    const [isExpired, setIsExpired] = useState<boolean>(false);
    const expireTime = 1000 * 60 * 60;

    const [{ data, fetching }] = useGetMyNftDetailQuery({
      pause: !isReady || !id,
      variables: {
        id,
        applicationId: currentApplicationId,
      },
      requestPolicy: 'network-only',
    });

    const onBackToItem = () => {
      push(`${collectionPagePath}/${id}`);
    };

    // EPUB ファイルの読み込み
    useEffect(() => {
      if (ref.current && isReady && !fetching) {
        const nfts = data?.myNfts.edges?.map(({ node }) => node);
        const currentNft = nfts?.[0];
        const unlockableContents = currentNft?.item?.unlockableContents;
        const epubContent = unlockableContents
          ?.filter((content) => {
            return isEPUBAsset(content.privateAsset?.mimeType);
          })
          ?.sort(dateFilter)[0];
        const epubUrl = generateBibiUrl(epubContent.privateAsset.url);

        // expire に合わせて見れなくなる
        setTimeout(() => {
          setIsExpired(true);
        }, expireTime);

        setCurrentUrl(epubUrl);
      }
    }, [query, isReady, fetching]);

    return (
      <div className="w-screen h-screen">
        <iframe
          ref={ref}
          src={currentUrl}
          width="100%"
          height="100%"
          sandbox="allow-scripts allow-same-origin allow-popups"
        />
        {isExpired && (
          <div
            className={
              'absolute top-0 left-0 right-0 bottom-0 dark:bg-wallet-base bg-wallet-light-base'
            }
          >
            <div className="flex flex-col items-center justify-center pt-20 text-wallet-light-primary dark:text-wallet-primary">
              <ExclamationCircleIcon className="w-40 h-40 mb-4" />
              <div className="text-center">
                <Typography variant="h2">
                  このリンクは有効期限切れです
                </Typography>
                <div className="py-6">
                  <Typography className="text-wallet-light-secondary dark:text-wallet-secondary">
                    このページを再読み込みしてください
                  </Typography>
                </div>
                <Button primary onClick={reload}>
                  再読み込みする
                </Button>
                <div
                  onClick={onBackToItem}
                  className="py-4 cursor-pointer hover:underline"
                >
                  <Typography variant="body2">
                    マイコレクションへ戻る
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return ViewerEPUBPage;
};
