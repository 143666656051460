import {
  customerErrorMessage,
  pickGraphqlError,
  useGetApplicationQuery,
  useSignInWithEmailLinkMutation,
} from '@collection-platform-frontend/api';
import {
  ConfirmLabels,
  ConfirmModal,
  useToast,
} from '@collection-platform-frontend/ui';
import { Router, useRouter } from 'next/router';
import { useCallback, useMemo, useState } from 'react';

import { AuthForm, AuthFormLabels } from '../../components/auth';

const siteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY as string;

const supportUrl =
  'https://support.anique.jp/hc/ja/articles/13677864311705-%E3%83%AD%E3%82%B0%E3%82%A4%E3%83%B3%E3%83%A1%E3%83%BC%E3%83%AB%E3%81%8C%E5%B1%8A%E3%81%8B%E3%81%AA%E3%81%84';

const defaultAuthLabels = (applicationName?: string): AuthFormLabels => {
  const actionLabel = 'ログイン / 新規登録';
  const title = applicationName
    ? `${applicationName}に</br>${actionLabel}する`
    : 'Anique Walletに</br>ログインする';

  const description = applicationName
    ? `Anique Walletで${applicationName}にアクセスして、あの作品のコレクションを手に入れましょう`
    : 'マイコレクションにアクセスして、</br>手に入れたアイテムを確認しましょう';

  const policy = `上記の『${actionLabel}』を選択することにより、私はAnique Walletの
    <a
      key="terms"
      href="/terms"
      class="underline cursor-pointer"
      target="_blank"
    >
      利用規約
    </a>と
    <a
      key="policy"
      href="/policy"
      class="underline cursor-pointer"
      target="_blank"
    >
      プライバシーポリシー
    </a>を読み、同意したことを認めます`;

  return {
    actionLabel,
    title,
    label: 'メールアドレス',
    description,
    helpLabel: 'Anique Walletとは',
    policy,
  };
};

const defaultMailConfirmLabels = (emailAddress?: string): ConfirmLabels => {
  return {
    title: 'メールを確認してください',
    message: `ログイン用メールアドレス<br />（${emailAddress}）へ<br />認証メールを送信しました。`,
    caption: `もしメールが届かない場合は、迷惑メールフォルダをご確認ください。ご不明な点は<a class="underline cursor-pointer select-none outline-none" target="_blank" href="${supportUrl}">こちら</a>`,
    resetLabel: 'メールアドレスを訂正する',
  };
};

const defaultHintLabels = (appName?: string): ConfirmLabels => {
  return {
    title: 'Anique Walletとは',
    message: appName
      ? `${appName}で取得したアイテムは、<br />Anique株式会社が提供する<br />「Anique Wallet」に保存され、<br />${appName}内で閲覧する事ができます。`
      : 'Anique Walletのマイコレクションで手に入れた作品のアイテムを一覧で確認することができます。',
    resetLabel: '閉じる',
  };
};

type AuthPageRouter = Router & {
  query: {
    app_id?: string;
    callbackUrl?: string;
  };
};

type RenderProps = {
  currentApplicationId?: string;
  getAuthLabels?: (appName?: string) => AuthFormLabels;
  getHintLabels?: (appName?: string) => ConfirmLabels;
  getMailConfirmLabels?: (emailAddress?: string) => ConfirmLabels;
};

export const AuthPageRender = ({
  currentApplicationId,
  getAuthLabels = defaultAuthLabels,
  getHintLabels = defaultHintLabels,
  getMailConfirmLabels = defaultMailConfirmLabels,
}: RenderProps = {}) => {
  const AuthPage = () => {
    const {
      query: { app_id, callbackUrl },
    } = useRouter() as AuthPageRouter;
    const [sentEmail, setSentEmail] = useState<string>();
    const [showHint, setShowHint] = useState<boolean>(false);

    const { failure } = useToast();

    const applicationId = currentApplicationId ?? app_id;
    const [{ data }] = useGetApplicationQuery({
      pause: !applicationId,
      variables: {
        applicationId: applicationId ?? '',
      },
      requestPolicy: 'network-only',
    });

    const [, signInWithEmailLink] = useSignInWithEmailLinkMutation();

    const onSubmit = async (email: string, recaptchaToken: string) => {
      const authUrl = new URL('/auth/callback', window.location.href);
      authUrl.searchParams.set('vm', email);

      if (callbackUrl) {
        authUrl.searchParams.set('callbackUrl', callbackUrl);
      }

      return await signInWithEmailLink({
        email,
        reCaptchaToken: recaptchaToken,
        callbackUrl: authUrl.toString(),
        applicationId,
      }).then(({ data, error }) => {
        if (error) {
          const { code, message } = pickGraphqlError(error) ?? { code: '' };
          failure(customerErrorMessage[code] ?? message);
          return;
        }

        if (data) {
          const sentMailAddress = data?.signInWithEmailLink.email;
          setSentEmail(sentMailAddress);
        }
      });
    };

    const onChangeAddress = useCallback(() => {
      setSentEmail(undefined);
    }, []);

    const onShowHint = useCallback(() => {
      setShowHint(true);
    }, []);

    const onHideHint = useCallback(() => {
      setShowHint(false);
    }, []);

    const app = data?.application;
    const authLabels = useMemo(() => getAuthLabels(app?.name), [app]);
    const confirmlabels = useMemo(
      () => getMailConfirmLabels(sentEmail),
      [sentEmail],
    );
    const hintlabels = useMemo(() => getHintLabels(app?.name), [app]);
    return (
      <div className="flex items-center h-screen">
        <AuthForm
          recaptchSiteKey={siteKey}
          application={{
            id: applicationId,
            name: app?.name,
            iconUrl: app?.iconAsset?.url,
          }}
          labels={authLabels}
          onSubmit={onSubmit}
          onShowHint={onShowHint}
        />
        <ConfirmModal
          show={!!sentEmail}
          labels={confirmlabels}
          onReset={onChangeAddress}
        />
        <ConfirmModal
          show={showHint}
          labels={hintlabels}
          onReset={onHideHint}
        />
      </div>
    );
  };
  return AuthPage;
};
