import { AchievementIconData } from '@collection-platform-frontend/api';
import { Hint, Typography } from '@collection-platform-frontend/ui';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';

import { Badge } from './badge';
import { BadgeCard } from './badge-card';
import { PointIcon } from './icons';

type UserBadgeProps = {
  achievementIconName: string;
  achievementPointName: string;
  customerPointBalance: number;
  customerExchangedPoint: number;
  achievementIcons: AchievementIconData[] | undefined;
};

export const UserBadges: FC<UserBadgeProps> = ({
  achievementIconName,
  achievementPointName,
  customerPointBalance,
  customerExchangedPoint,
  achievementIcons,
}) => {
  const hasAchievementIcons = achievementIcons && achievementIcons.length > 0;
  return (
    <div className="p-4 sm:p-8 bg-wallet-primary rounded-3xl dark:bg-wallet-container">
      {hasAchievementIcons ? (
        <Typography variant="h3" className="mb-1">
          {`あなたの保有${achievementIconName}`}
        </Typography>
      ) : null}

      <div className="flex flex-row flex-wrap items-center justify-start w-full gap-2 py-2 mb-1">
        {achievementIcons?.map((icon) => {
          return (
            <Hint
              key={icon.id}
              useBalloonStyle={false}
              target={
                <Badge
                  key={icon.id}
                  image={icon.iconNormalImageAsset?.url}
                  grayscale={!icon.achievedAt}
                />
              }
            >
              <BadgeCard
                className="max-w-[326px]"
                id={icon.id}
                title={icon.title}
                description={icon.description}
                image={icon.iconNormalImageAsset?.url}
                pointAmount={icon.achievementPoint}
                pointUnit={achievementPointName}
                achievedAt={icon.achievedAt}
              />
            </Hint>
          );
        })}
      </div>

      <div className="flex flex-col">
        <div className="flex items-center justify-start gap-x-1">
          <PointIcon className="w-[15px] h-[15px]" />
          <Typography variant="caption" className="text-wallet-secondary">
            {achievementPointName}
          </Typography>
        </div>
        <div className="flex items-center justify-between my-2">
          <Typography variant="h4" className="mb-1">
            交換可能
          </Typography>
          <div className="flex items-center gap-1">
            <Typography variant="h4">{`${
              customerPointBalance - customerExchangedPoint
            } ${achievementPointName}`}</Typography>
            <Hint
              target={
                <InformationCircleIcon
                  className="w-5 h-5 text-wallet-secondary"
                  aria-hidden="true"
                />
              }
            >
              <div className="flex flex-col gap-y-1">
                <Typography variant="caption" className="text-wallet-secondary">
                  {`交換可能${achievementPointName}`}
                </Typography>
                <Typography variant="body2">
                  アイテムと交換できるポイント数
                </Typography>
              </div>
            </Hint>
          </div>
        </div>
        <hr className="border-wallet-light-secondary" />
        <div className="flex items-center justify-between my-2">
          <Typography variant="h4" className="mb-1">
            累計獲得
          </Typography>
          <div className="flex items-center gap-1">
            <Typography variant="h4">{`${customerPointBalance} ${achievementPointName}`}</Typography>
            <Hint
              target={
                <InformationCircleIcon
                  className="w-5 h-5 text-wallet-secondary"
                  aria-hidden="true"
                />
              }
            >
              <div className="flex flex-col gap-y-1">
                <Typography variant="caption" className="text-wallet-secondary">
                  {`累計獲得${achievementPointName}`}
                </Typography>
                <Typography variant="body2">
                  ミッションで獲得したポイントの累計総数。
                </Typography>
              </div>
            </Hint>
          </div>
        </div>
      </div>
    </div>
  );
};
