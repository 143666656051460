import { QuizStatus } from '@collection-platform-frontend/api';
import { cn } from '@collection-platform-frontend/shared';
import {
  Button,
  ProgressBar,
  Typography,
} from '@collection-platform-frontend/ui';
import { CheckCircleIcon, LockClosedIcon } from '@heroicons/react/24/solid';
import { FC } from 'react';

import BaseModal, { BaseModalProps } from './base';

type QuizProgressBarProps = {
  total: number;
  progress: number;
  className?: string;
};

const QuizProgressBar: FC<QuizProgressBarProps> = ({
  total,
  progress,
  className,
}) => {
  const color = '#F8C205';
  return (
    <ProgressBar
      className={className}
      progress={progress}
      total={total}
      color={color}
      minPx={30}
    />
  );
};

type QuizStatusBadge = {
  status: QuizStatus;
  className?: string;
};

const QuizStatusBadge: FC<QuizStatusBadge> = ({ status, className }) => {
  const styles = {
    base: 'w-9 h-9',
    cleared: 'text-wallet-success dark:text-wallet-success',
    locked: 'text-wallet-thirdly',
  };
  return (
    <>
      {status === 'achieved' && (
        <CheckCircleIcon
          className={cn(styles.base, styles.cleared, className)}
        />
      )}
      {status === 'locked' && (
        <LockClosedIcon className={cn(styles.base, styles.locked, className)} />
      )}
      {status === 'challengeable' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className={cn(styles.base, className)}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      )}
    </>
  );
};

type Quiz = {
  title: string;
  status: QuizStatus;
};

type QuizListProps = {
  quizzes: Quiz[];
};

const QuizList: FC<QuizListProps> = ({ quizzes }) => {
  const styles = {
    base: 'w-full my-2 py-4 border-2 rounded-xl shadow-5xl text-black text-xs',
    achieved: 'text-wallet-light-inactive dark:border-wallet-primary/50',
    challengeable:
      'border-wallet-light-inactive/50 dark:text-wallet-primary dark:border-wallet-primary',
    locked: 'text-wallet-light-inactive dark:border-wallet-primary/50',
  };

  return (
    <>
      {quizzes.map((quiz, idx) => (
        <div key={idx} className={cn(styles.base, styles[quiz.status])}>
          <div className="flex flex-row items-center px-4 space-x-2">
            <div>
              <QuizStatusBadge status={quiz.status} />
            </div>
            <Typography variant="h4" className="text-lg text-start">
              {quiz.title}
            </Typography>
          </div>
        </div>
      ))}
    </>
  );
};

type NextButtonProps = {
  onNext: () => void;
  isContinue: boolean;
  loading: boolean;
};

const NextButton: FC<NextButtonProps> = ({ onNext, isContinue, loading }) => {
  return (
    <Button
      className="px-8 mt-4 max-w-fit"
      primary
      onClick={onNext}
      loading={loading}
    >
      {isContinue ? '続ける' : '開始する'}
    </Button>
  );
};

type Props = BaseModalProps & {
  show: boolean;
  total: number;
  progress: number;
  loading: boolean;
  quizzes: Quiz[];
  onNext: () => void;
};

export const QuizzesChallengeModal: FC<Props> = ({
  show,
  total,
  progress,
  loading,
  quizzes,
  onNext,
  onClose,
}) => {
  const isContinue = progress > 0;

  return (
    <BaseModal show={show} onClose={!loading ? onClose : undefined}>
      <Typography variant="h2" className="mb-8 text-sm">
        全{total}問
      </Typography>
      <QuizProgressBar className="mb-4" total={total} progress={progress} />
      <QuizList quizzes={quizzes} />
      <NextButton onNext={onNext} isContinue={isContinue} loading={loading} />
    </BaseModal>
  );
};
