import { Modal } from '@collection-platform-frontend/ui';
import { FC } from 'react';

export const Processing: FC = () => {
  return (
    <div className="flex flex-col items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
      <Modal show={true}>
        <div className="inline-block w-full max-w-xs overflow-hidden text-left align-middle transition-all transform">
          <div className="flex flex-col items-center text-white bg-zinc-800 p-14 rounded-3xl gap-y-2 min-w-[300px]">
            <div className="flex justify-center">
              <div className="w-20 h-20 border-4 border-white rounded-full animate-spin border-t-transparent"></div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
