import { FirebaseError } from '@firebase/util';
import { UserCredential } from 'firebase/auth';
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth';
import { Router, useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { FC } from 'react';

import { Processing } from './processing';

type CallbackRouter = Router & {
  query: {
    vm: string;
  };
};

export type ValidateResult = {
  error?: FirebaseError;
  credential?: UserCredential;
};

export type AuthCallbackProps = {
  onValidate?: (result: ValidateResult) => void;
};

export const AuthCallback: FC<AuthCallbackProps> = ({ onValidate }) => {
  const router = useRouter() as CallbackRouter;
  const [validate, isValidate] = useState<boolean>(false);

  const { query } = router;

  useEffect(() => {
    const auth = getAuth();
    if (!query.vm || !isSignInWithEmailLink(auth, window.location.href)) {
      return;
    }

    if (!validate) {
      isValidate(true);

      signInWithEmailLink(auth, query.vm, window.location.href)
        .then((credential) => {
          onValidate && onValidate({ credential });
        })
        .catch((e: unknown) => {
          if (e instanceof FirebaseError) {
            onValidate && onValidate({ error: e as FirebaseError });
          }
        });
    }
  }, [onValidate, query, validate]);

  return <Processing />;
};
