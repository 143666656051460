import { Typography } from '@collection-platform-frontend/ui';
import { FC } from 'react';

export type PropertyItemProps = {
  label?: string;
  value?: string;
  skeleton?: boolean;
};

export const PropertyItem: FC<PropertyItemProps> = ({
  label,
  value,
  skeleton,
}) => {
  return (
    <div className="flex flex-col p-4 text-left border rounded-3xl border-wallet-light-inactive dark:border-wallet-inactive gap-y-2">
      <Typography
        variant="body2"
        className="truncate text-wallet-light-thirdly dark:text-wallet-thirdly"
        skeleton={skeleton}
      >
        {label}
      </Typography>
      <Typography variant="body2" className="truncate" skeleton={skeleton}>
        {value}
      </Typography>
    </div>
  );
};
