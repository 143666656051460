import {
  DropItemData,
  ExchangeItemData,
} from '@collection-platform-frontend/api';
import { useInterval } from '@collection-platform-frontend/shared';
import { Button, Image, Typography } from '@collection-platform-frontend/ui';
import { FC, useCallback } from 'react';
import { useReward } from 'react-rewards';

import BaseModal from './base';

type Props = {
  show: boolean;
  exchangeItem?: ExchangeItemData;
  dropItem?: DropItemData;
  onCollection: (id: string) => void;
  onClose: () => void;
};

export const ExchangePointToItemModal: FC<Props> = ({
  show,
  exchangeItem,
  // dropItem,
  onCollection,
  onClose,
}) => {
  const { reward: rewardLeft, isAnimating: isAnimatingLeft } = useReward(
    'rewardLeft',
    'confetti',
    {
      angle: 70,
      lifetime: 240,
      spread: 90,
      elementCount: 100,
      position: 'fixed',
    },
  );
  const { reward: rewardRight, isAnimating: isAnimatingRight } = useReward(
    'rewardRight',
    'confetti',
    {
      angle: 100,
      lifetime: 240,
      spread: 90,
      elementCount: 100,
      position: 'fixed',
    },
  );

  useInterval(() => {
    if (!show) {
      return;
    }

    if (!isAnimatingRight || !isAnimatingLeft) {
      rewardLeft();
      rewardRight();
    }
  });

  const onCollectionItem = useCallback(() => {
    if (!exchangeItem?.id) {
      return;
    }

    onCollection && onCollection(exchangeItem.id);
  }, [onCollection, exchangeItem]);

  if (!exchangeItem) {
    return null;
  }

  return (
    <BaseModal show={show} onClose={onClose}>
      <div className="mb-6">
        <Typography variant="h2" className="mb-2">
          アイテムを取得しました！
        </Typography>
        <Typography variant="body2" className="text-wallet-light-secondary">
          受け取りのアイテムは、マイコレクションから確認ができます。
        </Typography>
      </div>
      <div className="flex flex-col items-center mb-6 space-y-2">
        {exchangeItem?.bannerImageAsset?.url && (
          <Image
            className="h-[254px] w-[254px] rounded-3xl overflow-hidden"
            objectFit="cover"
            layout="fill"
            src={exchangeItem.bannerImageAsset.url}
            alt="exchange_item_image"
          />
        )}
        <Typography variant="h3">{exchangeItem?.title}</Typography>
      </div>

      <div className="flex flex-col mb-4">
        <Button primary className="w-[240px]" onClick={onCollectionItem}>
          マイコレクションで確認する
        </Button>
      </div>
      <div className="relative w-full">
        <span id="rewardLeft" className="absolute bottom-0 left-0" />
        <span id="rewardRight" className="absolute bottom-0 right-0" />
      </div>
    </BaseModal>
  );
};
