import { Typography } from '@collection-platform-frontend/ui';
import { FC, useEffect, useState } from 'react';

import { AchievedMissionItem } from '../mission-item';
import BaseModal from './base';

type Props = {
  show: boolean;
  pointUnit: string;
  mission?: {
    title?: string | null;
    pointAmount?: number | null;
  };
  onClose: () => void;
};

export const MissionAchievementModal: FC<Props> = ({
  show,
  pointUnit,
  mission: currentMission,
  onClose,
}) => {
  // NOTE: モーダルを閉じるアニメーション時にコンテンツを表示し続けるために一時的にデータを残しておく。
  const [previousMission, setPreviousMission] = useState<Props['mission']>();
  useEffect(() => {
    if (currentMission) setPreviousMission(currentMission);
  }, [currentMission]);

  const mission = currentMission || previousMission;

  return (
    <BaseModal show={show} onClose={onClose}>
      {mission && (
        <>
          <div className="mb-6">
            <Typography variant="h2" className="mb-2">
              ミッション達成
              <br />
              おめでとうございます！
            </Typography>
            <Typography variant="body2" className="text-wallet-light-secondary">
              {`${mission.pointAmount}${pointUnit}を手に入れました。`}
            </Typography>
          </div>

          <AchievedMissionItem
            className="mb-5"
            title={mission.title}
            pointAmount={mission.pointAmount}
            pointUnit={pointUnit}
          />
        </>
      )}
    </BaseModal>
  );
};
