import {
  ArticleSection,
  ArticleSectionProps,
} from '@collection-platform-frontend/ui';

const policies: ArticleSectionProps[] = [
  {
    contents: [
      'Anique株式会社（以下、「当社」といいます。）は、当社が提供するAnique Walletおよびウェブサイト（以下、「本サービス」といいます。）におけるユーザーの個人情報やそれに準じる非個人情報（以下「個人情報等」といいます）の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。',
    ],
  },
  {
    title: '第1条 個人情報等',
    contents: [
      '個人情報とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報及び容貌、指紋、声紋にかかるデータ、及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。',
      '当社は、個人情報に該当するか否かにかかわらず、サービス利便性向上やメンテナンスに必要な以下の情報についても、本サービス内で自動的に収集します。',
      [
        'ハードウェアモデル',
        'オペレーティングシステムとバージョン',
        'MACアドレス',
        '固有のデバイス識別子（「UDID」）',
        '電話番号',
        'International Mobile Equipment Identity（「IMEI」、端末識別番号）',
        'その他サービスへのアクセスに使用するコンピュータまたはデバイス、ネットワークに関する情報',
      ],
      '当社は、ログファイルに含まれるデータを自動的に収集して使用することがあります。ログファイル内の情報には、あなたのIPアドレス、あなたのISP、あなたが本サービスにアクセスしたときに使用したウェブブラウザ、あなたが本サービスにアクセスした時間、あなたが本サービス上でアクセスしたウェブページ、その他の匿名利用データが含まれます。',
      '当社は、「Cookie」と呼ばれる技術や類似の技術を使用することがあります。Cookieはあなたをユニークなユーザーとして識別する小さなファイルです。当社は、本サービスの品質を向上させ、本サービスをより使いやすくするためにCookieを使用することがあります。円滑なサービス利用をユーザーに提供するために、特定のユーザーセッションに関する情報をCookieに保存することによってこれを行います。当社の「サービスプロバイダ」の中には（以下に定義されているように）Cookieまたは同様の技術を使用するものがあります。既存のCookieを削除するか、Cookieを完全に無効にするようにブラウザを設定した場合、本サービスの特定の機能が機能しなくなる可能性があります。',
    ],
  },
  {
    title: '第2条 個人情報等の収集方法',
    contents: [
      '当社は、本サービスにおいてにおいてユーザーから氏名、住所、メールアドレス、ウォレットアドレスを含む個人情報をお尋ねすることがあります。また、本サービスを通じて、またはコンテスト、懸賞、および/またはその他のプロモーションに関連して提供された個人情報等（まとめて「個人情報」といいます。）も収集することがあります。',
      '当社は、ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を、当社の提携先（情報提供元、広告主、広告配信先などを含みます。以下、｢提携先｣といいます。）などから収集することがあります。',
      'ユーザーがサービスを通じてメッセージを送信したりコンテンツを投稿したりする場合、ユーザー名、実際の名前、その他の個人情報がメッセージまたは投稿に含まれることがあります。ユーザーが任意に個人情報等を公開することにより損害を被ったとしても、弊社は責任を負いません。',
    ],
  },
  {
    title: '第3条 個人情報等を収集・利用する目的',
    description: '当社が個人情報等を収集・利用する目的は、以下のとおりです。',
    contents: [
      '本サービスの提供・運営のため',
      'ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）',
      'ユーザーが利用中の本サービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため',
      'メンテナンス、重要なお知らせなど必要に応じたご連絡のため',
      '利用規約に違反したユーザーや、不正・不当な目的で本サービスを利用しようとするユーザーの特定をし、ご利用をお断りするため',
      'ユーザーにご自身の登録情報の閲覧や変更、削除、ご利用状況の閲覧を行っていただくため',
      '有料サービスにおいて、ユーザーに利用料金を請求するため',
      'ユーザーに向けて本サービスに関連する製品・グッズ等を配送するため',
      '本サービスの特典としてユーザーの個人情報をネームプレートや証明書等に記載するため',
      '上記の利用目的に付随する目的',
    ],
  },
  {
    title: '第4条 利用目的の変更',
    contents: [
      '当社は、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。',
      '利用目的の変更を行った場合には、変更後の目的について、当社所定の方法により、ユーザーに通知し、または当社ウェブサイト上に公表するものとします。',
    ],
  },
  {
    title: '第5条 個人情報等の第三者提供',
    contents: [
      '当社は、本サービスおよび第三者のウェブサイトで、お客様が関心を持つ可能性がある商品およびサービスに関する広告を提供するために、本サービスの使用に関する情報を使用する第三者の広告会社と協力することがあります。 本サービスから、これらの会社はあなたの端末上にCookieを置いたり認識したり、あるいはウェブビーコンやピクセルタグのような他の技術を使用するかもしれません。 当社のプライバシーポリシーは、第三者の広告配信会社があなたから収集する可能性のある情報の使用をカバーしていません。広告CookieおよびそのようなCookieからの情報の収集を拒絶する場合は、各広告会社等にお問い合わせください。',
      '当社は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。',
      [
        '人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき',
        '公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき',
        '国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき',
        '予め次の事項を告知あるいは公表し、かつ当社が個人情報保護委員会に届出をしたとき',
        [
          '利用目的に第三者への提供を含むこと',
          '第三者に提供されるデータの項目',
          '第三者への提供の手段または方法',
          '本人の求めに応じて個人情報の第三者への提供を停止すること',
          '本人の求めを受け付ける方法',
        ],
      ],
      '前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。',
      [
        '当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合',
        '合併その他の事由による事業の承継に伴って個人情報が提供される場合',
        '個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いた場合',
      ],
    ],
  },
  {
    title: '第6条 個人情報等の開示',
    contents: [
      '当社は、本人から個人情報の開示を求められたときは、本人に対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。なお、個人情報の開示に際しては、所定の手数料を申し受けます。',
      '本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合',
      '当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合その他法令に違反することとなる場合',
      '前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。',
    ],
  },
  {
    title: '第7条 個人情報等の訂正および削除',
    contents: [
      'ユーザーは、当社の保有する自己の個人情報が誤った情報である場合には、当社が定める手続きにより、当社に対して個人情報の訂正、追加または削除（以下、「訂正等」といいます。）を請求することができます。',
      '当社は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正等を行うものとします。',
      '当社は、前項の規定に基づき訂正等を行った場合、または訂正等を行わない旨の決定をしたときは遅滞なく、これをユーザーに通知します。',
    ],
  },
  {
    title: '第8条 個人情報等の利用停止等',
    contents: [
      '当社は、本人から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行います。',
      '前項の調査結果に基づき、その請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の利用停止等を行います。',
      '当社は、前項の規定に基づき利用停止等を行った場合、または利用停止等を行わない旨の決定をしたときは、遅滞なく、これをユーザーに通知します。',
      '前2項にかかわらず、利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じるものとします。',
    ],
  },
  {
    title: '第9条 欧州連合居住者の個人情報にかかる取り扱い（GDPR）',
    contents: [
      '欧州連合一般データ保護規則（「GDPR」）は、欧州連合（以下「EU」といいます）の居住者の個人データを保護するために特別な措置を講じることを要求しています。本ポリシーとGDPRとの間に矛盾がある場合、GDPRの原則がEUに居住するユーザーに関して適用されるものとします。',
      'GDPRの下で、EUに居住するユーザーは当社に保存されている個人データのコピーを要求する権利があります（GDPR第15条）。また、個人データの訂正（GDPR第16条）、削除（GDPR第17条）または制限（GDPR第18条）を要求する権利を有します。',
      '当社はEUに居住するユーザーからメールアドレスを提供いただくことにより、ユーザーが要求した情報を送ったり、データの訂正、削除、制限を求める要求の完了を確認することができます（GDPR第19条）。ユーザー本人以外からの個人データにかかる要求は、当社のデータ保護責任者に送ることができます。',
      'EUに居住するユーザーが当社の個人データの取扱いに異議がある場合、いつでも当該個人データの処理に異議を述べる権利があります（GDPR第21条）。個人データの処理に関する異議は、Aniqueお問い合わせページからご連絡ください。',
    ],
  },
  {
    title: '第10条 子供の個人情報',
    contents: [
      '本サービスは13歳未満の子供を対象としていませんが、そのような子供から個人を特定できる情報を故意に収集することはありません。13歳未満のユーザーから誤って個人を特定できる情報を受け取ったことが判明した場合、その情報を記録から削除します。 13歳未満の子供から個人を特定できる情報を故意に収集することはないため、そのような情報を第三者に故意に配布することもありません。GDPR上個人情報の収集について同意が求められる場合、16歳未満（または特定の国の法律で指定されているような年齢未満）のユーザーから個人情報を収集することはありません。',
    ],
  },
  {
    title: '第11条 外部リンク',
    contents: [
      '本サービスには、当社が管理できない第三者によって運営および維持されているウェブサイトへのリンクが含まれている場合があります。このようなリンク先のウェブサイトのプライバシーポリシーは、当社のプライバシーポリシーとは異なる場合があります。ユーザーはそのような外部リンクに対してはユーザー自身の責任でアクセスすることに同意します。',
    ],
  },
  {
    title: '第12条 プライバシーポリシーの変更',
    contents: [
      '本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、変更することができるものとします。',
      '当社が別途定める場合を除いて、変更後のプライバシーポリシーは、当社ウェブサイトに掲載したときから効力を生じるものとします。',
    ],
  },
  {
    title: '第13条 お問い合わせ窓口',
    description:
      '本ポリシーに関するお問い合わせは、下記の連絡先までお願いいたします。</br></br>連絡先：Anique株式会社 個人情報対応窓口</br><a href="mailto:support@anique.jp" class="underline">support@anique.jp</a>',
  },
];

export const PrivacyPolicy = () => {
  return (
    <>
      {policies.map((props, i) => {
        return <ArticleSection key={i} {...props} />;
      })}
    </>
  );
};
