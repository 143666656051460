import {
  dateFilter,
  getMediaType,
  isARModelAsset,
  isModelAsset,
  MediaType,
  mediaType2ds,
  mediaType3ds,
  mediaTypeDownload,
  mediaTypeEpub,
} from '@collection-platform-frontend/api';
import {
  Button,
  DynamicModelViewer,
  Hint,
  Image,
  Separator,
  Typography,
  WalletUser,
} from '@collection-platform-frontend/ui';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import {
  CheckBadgeIcon,
  LockClosedIcon,
  LockOpenIcon,
} from '@heroicons/react/24/solid';
import { FC, ReactNode, useState } from 'react';

import { ItemData } from './item';
import { PropertyItem } from './property';

export type ItemDetailData = ItemData & {
  serialNumber?: number | null;
  editionSize?: number;
  description?: string;
  copyrights?: string;
  properties?: {
    name: string;
    value: string;
  }[];
  canNftListing?: boolean;
  canNftTransfer?: boolean;
  unlockableContents?: {
    unlocked: boolean;
    privateAsset?: {
      mimeType: string;
    } | null;
  }[];
  publicContents?: {
    publicAsset?: {
      mimeType: string;
      url: string;
    } | null;
    sortIndex?: number;
    createdAt: Date;
  }[];
};

export type ItemDetailProps = ItemDetailData & {
  skeleton?: boolean;
  content?: ReactNode;
  user?: WalletUser;
  onView?: () => void;
  onView3D?: () => void;
  onViewEpub?: () => void;
  onViewDownloadable?: () => void;
};

type UnlockableContentsState = 'none' | 'unlocked' | 'locked';

const getUnlockableContentsState = (
  contents: ItemDetailProps['unlockableContents'],
  targetMediaTypes: MediaType[],
): UnlockableContentsState => {
  const targets = (contents ?? []).filter((c) => {
    const contentType =
      c.privateAsset?.mimeType && getMediaType(c.privateAsset?.mimeType);
    if (!contentType) {
      return false;
    }

    return targetMediaTypes.includes(contentType);
  });

  return targets?.length > 0
    ? targets.some((t) => t.unlocked)
      ? 'unlocked'
      : 'locked'
    : 'none';
};

const NftItemDetail: FC<ItemDetailProps> = ({
  content,
  name,
  contractName,
  serialNumber,
  editionSize,
  description,
  copyrights,
  skeleton,
  properties,
  canNftListing,
  canNftTransfer,
  unlockableContents,
  user,
  onView,
  onView3D,
  onViewEpub,
  onViewDownloadable,
}) => {
  const unlockableContents2dState = getUnlockableContentsState(
    unlockableContents,
    mediaType2ds,
  );
  const unlockableContents3dState = getUnlockableContentsState(
    unlockableContents,
    mediaType3ds,
  );
  const unlockableContentsEpubState = getUnlockableContentsState(
    unlockableContents,
    mediaTypeEpub,
  );
  const unlockableContentsDownloadableState = getUnlockableContentsState(
    unlockableContents,
    mediaTypeDownload,
  );
  const someUnlockableContentsExist = [
    unlockableContents2dState,
    unlockableContents3dState,
    unlockableContentsEpubState,
    unlockableContentsDownloadableState,
  ].some((state) => state !== 'none');
  const someUnlockableContentsUnlocked = [
    unlockableContents2dState,
    unlockableContents3dState,
    unlockableContentsEpubState,
    unlockableContentsDownloadableState,
  ].some((state) => state === 'unlocked');

  return (
    <div className="flex flex-col items-center text-wallet-light-primary dark:text-wallet-primary">
      {content}
      <div className="w-full px-2">
        <div className="flex flex-col py-4 gap-y-2">
          <Typography
            skeleton={skeleton}
            className="text-wallet-light-thirdly dark:text-wallet-thirdly"
          >
            <div className="flex flex-row items-center space-x-2 truncate">
              <div className="truncate">{contractName}</div>
              <div className="inline-flex z-element">
                <Hint
                  target={
                    <CheckBadgeIcon className="w-6 h-6" aria-hidden="true" />
                  }
                >
                  <Typography>認証済みコレクションです</Typography>
                </Hint>
              </div>
            </div>
          </Typography>
          <div className="flex items-center space-x-1">
            <Typography skeleton={skeleton} variant="h2">
              {name}
            </Typography>
          </div>
          <Typography
            skeleton={skeleton}
            className="text-wallet-light-thirdly dark:text-wallet-thirdly"
          >
            {serialNumber
              ? `#${serialNumber} / ${editionSize}`
              : `# - / ${editionSize}`}
          </Typography>
          {user?.profileIconUrl && (
            <div className="flex items-center py-2">
              <Image
                className="w-10 h-10"
                imageClassName="rounded-full"
                skeletonClassName="rounded-full"
                layout="fill"
                src={user.profileIconUrl}
                alt="profile"
              />
              <div className="flex flex-col pl-3 space-y-1">
                <Typography
                  className="truncate text-wallet-light-secondary"
                  variant="body2"
                >
                  保有者
                </Typography>
                <Typography variant="h4">{user?.nickName}</Typography>
              </div>
            </div>
          )}
        </div>
        {someUnlockableContentsExist && (
          <div className="p-8 mb-8 rounded-2xl bg-wallet-primary dark:bg-wallet-container">
            <div className="pb-6">
              <Typography variant="h3">保有者限定特典</Typography>
              {!someUnlockableContentsUnlocked && (
                <Typography
                  className="mt-2 text-wallet-light-secondary dark:text-wallet-secondary"
                  variant="caption"
                >
                  このアイテムの保有者のみ限定特典を閲覧できます。
                </Typography>
              )}
            </div>
            {unlockableContents2dState !== 'none' && (
              <Button
                primary
                className="mb-6"
                onClick={onView}
                disabled={unlockableContents2dState === 'locked'}
              >
                <div className="flex flex-row items-center justify-center space-x-1">
                  {unlockableContents2dState === 'locked' ? (
                    <LockClosedIcon className="w-6 h-6" />
                  ) : (
                    <LockOpenIcon className="w-6 h-6" />
                  )}
                  <Typography variant="h3">特典を確認する</Typography>
                </div>
              </Button>
            )}
            {unlockableContentsEpubState !== 'none' && onViewEpub && (
              <Button
                primary
                className="mb-6"
                onClick={onViewEpub}
                disabled={unlockableContents3dState === 'locked'}
              >
                <div className="flex flex-row items-center justify-center space-x-1">
                  {unlockableContents3dState === 'locked' ? (
                    <LockClosedIcon className="w-6 h-6" />
                  ) : (
                    <LockOpenIcon className="w-6 h-6" />
                  )}
                  <Typography variant="h3">特典を確認する</Typography>
                </div>
              </Button>
            )}
            {unlockableContentsDownloadableState !== 'none' &&
              onViewDownloadable && (
                <Button
                  primary
                  className="mb-6"
                  onClick={onViewDownloadable}
                  disabled={unlockableContents3dState === 'locked'}
                >
                  <div className="flex flex-row items-center justify-center space-x-1">
                    {unlockableContents3dState === 'locked' ? (
                      <LockClosedIcon className="w-6 h-6" />
                    ) : (
                      <LockOpenIcon className="w-6 h-6" />
                    )}
                    <Typography variant="h3">特典をダウンロードする</Typography>
                  </div>
                </Button>
              )}
            {unlockableContents3dState !== 'none' && onView3D && (
              <Button
                primary
                className="mb-6"
                onClick={onView3D}
                disabled={unlockableContents3dState === 'locked'}
              >
                <div className="flex flex-row items-center justify-center space-x-1">
                  {unlockableContents3dState === 'locked' ? (
                    <LockClosedIcon className="w-6 h-6" />
                  ) : (
                    <LockOpenIcon className="w-6 h-6" />
                  )}
                  <Typography variant="h3">3D特典を確認する</Typography>
                </div>
              </Button>
            )}

            <div className="flex flex-row items-center justify-start space-x-1">
              <Typography variant="h3">保有者限定特典とは</Typography>
              <Hint
                target={
                  <InformationCircleIcon
                    className="w-5 h-5 cursor-pointer"
                    aria-hidden="true"
                  />
                }
              >
                <Typography>
                  保有者限定特典とは、アイテムを保有している人だけが閲覧できるコンテンツです。
                  <br />
                  画像・動画・音楽・テキストなど様々な種類があり、アイテムにより内容は異なります。
                </Typography>
              </Hint>
            </div>
          </div>
        )}
        <Separator />
        <div className="flex flex-col w-full pt-8 pb-8 gap-y-2">
          <Typography
            variant="h3"
            skeleton={skeleton}
            className="text-wallet-light-thirdly dark:text-wallet-thirdly"
          >
            詳細
          </Typography>
          <Typography skeleton={skeleton} variant="body2">
            <span
              dangerouslySetInnerHTML={{
                __html:
                  description?.replace(new RegExp(/\n/, 'g'), '<br/>') ?? '',
              }}
            />
          </Typography>
          <div className="flex items-center justify-between gap-x-2">
            <Typography variant="body2" skeleton={skeleton}>
              {copyrights}
            </Typography>
          </div>
        </div>
        <Separator />
        {properties && (
          <>
            <div className="flex flex-col w-full pt-8 pb-8 gap-y-2">
              <Typography
                variant="h3"
                skeleton={skeleton}
                className="text-wallet-light-thirdly dark:text-wallet-thirdly"
              >
                プロパティ
              </Typography>
              <div className="grid grid-cols-2 gap-4">
                {properties?.map(({ name, value }, i) => {
                  if (value.length > 8) {
                    return (
                      <Hint
                        key={i}
                        target={
                          <PropertyItem
                            label={name}
                            value={value}
                            skeleton={skeleton}
                          />
                        }
                      >
                        <div className="flex flex-col space-y-2">
                          <Typography
                            variant="body2"
                            className="text-wallet-light-thirdly dark:text-wallet-thirdly"
                          >
                            {name}
                          </Typography>
                          <Typography variant="body2">{value}</Typography>
                        </div>
                      </Hint>
                    );
                  } else {
                    return (
                      <PropertyItem
                        key={i}
                        label={name}
                        value={value}
                        skeleton={skeleton}
                      />
                    );
                  }
                })}
              </div>
            </div>
            <Separator />
          </>
        )}
        <div className="flex flex-col w-full pt-8 pb-8 gap-y-2">
          <div className="flex items-center gap-x-1">
            <Typography
              variant="h3"
              skeleton={skeleton}
              className="text-wallet-light-thirdly dark:text-wallet-thirdly"
            >
              アイテムの移動
            </Typography>
            <Hint
              placement="bottom"
              target={
                <InformationCircleIcon className="w-5 h-5" aria-hidden="true" />
              }
            >
              <Typography key="1">
                発行者によって許可されているアイテムだけが出品・転送できます
              </Typography>
            </Hint>
          </div>
          <div className="flex flex-col px-6 py-4 space-y-2 border rounded-3xl border-wallet-light-inactive dark:border-wallet-light-inactive">
            <div className="flex items-center justify-between gap-x-2">
              <Typography
                variant="body2"
                className="text-wallet-light-thirdly dark:text-wallet-thirdly"
                skeleton={skeleton}
              >
                出品
              </Typography>
              <Typography variant="body2" skeleton={skeleton}>
                {canNftListing ? '出品できます' : '出品できません'}
              </Typography>
            </div>
            <div className="flex items-center justify-between gap-x-2">
              <Typography
                variant="body2"
                className="text-wallet-light-thirdly dark:text-wallet-thirdly"
                skeleton={skeleton}
              >
                転送
              </Typography>
              <Typography variant="body2" skeleton={skeleton}>
                {canNftTransfer ? '転送できます' : '転送できません'}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ItemDetail: FC<
  ItemDetailProps & {
    isOwnItem?: boolean;
    image?: string;
  }
> = ({ image, skeleton, publicContents, isOwnItem, ...rest }) => {
  const [contentLoaded, setContentLoaded] = useState<boolean>(false);

  const loading = skeleton || !contentLoaded;

  const modelContent = publicContents
    ?.filter((content) => {
      return isModelAsset(content.publicAsset?.mimeType);
    })
    ?.sort(dateFilter)[0];

  const arModelContent = publicContents
    ?.filter((content) => {
      return isARModelAsset(content.publicAsset?.mimeType);
    })
    ?.sort(dateFilter)[0];

  const onLoadingComplete = () => {
    setContentLoaded(true);
  };

  return (
    <NftItemDetail
      {...rest}
      skeleton={loading}
      content={
        modelContent?.publicAsset ? (
          <div className="relative bg-zinc-100 rounded-3xl w-[320px] h-[320px] overflow-hidden">
            <DynamicModelViewer
              cameraOrbit="0deg 90deg 105%"
              src={modelContent.publicAsset?.url}
              iosSrc={isOwnItem ? arModelContent?.publicAsset?.url : undefined}
              onModelVisibility={onLoadingComplete}
            />
          </div>
        ) : (
          <Image
            className="w-full overflow-hidden rounded-3xl z-base"
            src={image}
            width={400}
            height={400}
            objectFit="contain"
            layout="responsive"
            skeleton={loading}
            onLoadingComplete={onLoadingComplete}
          />
        )
      }
    />
  );
};
