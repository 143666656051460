import {
  Button,
  Container,
  Input,
  Typography,
} from '@collection-platform-frontend/ui';
import NextImage from 'next/legacy/image';
import {
  ChangeEvent,
  FC,
  FormEvent,
  MutableRefObject,
  useCallback,
  useRef,
  useState,
} from 'react';
import _ReCAPTCHA, { ReCAPTCHAProps } from 'react-google-recaptcha';
import isEmail from 'validator/lib/isEmail';

import { AniqueLogo } from './anique-logo';

const ReCAPTCHA = _ReCAPTCHA as unknown as React.FC<
  ReCAPTCHAProps & {
    ref?: MutableRefObject<any>;
  }
>;

export type AuthFormLabels = {
  title: string;
  description: string;
  label: string;
  actionLabel: string;
  helpLabel: string;
  policy: string;
};

type RelatedApplication = {
  id?: string;
  name?: string;
  iconUrl?: string;
};

type AuthFormProps = {
  recaptchSiteKey: string;
  application?: RelatedApplication;
  labels: AuthFormLabels;
  onSubmit?: (email: string, recaptchaToken: string) => Promise<void>;
  onShowHint?: () => void;
};

export const AuthForm: FC<AuthFormProps> = ({
  application,
  labels,
  recaptchSiteKey,
  onSubmit,
  onShowHint,
}) => {
  const reCaptchaRef = useRef(null);
  const [email, setEmail] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [processing, setProcessing] = useState<boolean>(false);

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
  }, []);

  const _onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!email) {
        return;
      }

      if (!isEmail(email)) {
        setErrorMessage('invalid email address.');
        return;
      }

      if (!reCaptchaRef.current) {
        setErrorMessage('not found recaptcha service.');
        return;
      }

      const reCaptcha = reCaptchaRef.current as _ReCAPTCHA;
      const reCaptchaToken = await reCaptcha.executeAsync();
      reCaptcha?.reset();

      if (!reCaptchaToken) {
        setErrorMessage('invalid recaptcha token.');
        return;
      }

      setProcessing(true);

      onSubmit &&
        (await onSubmit(email, reCaptchaToken).then(() => {
          setProcessing(false);
          setErrorMessage(undefined);
        }));
    },
    [onSubmit, email],
  );

  return (
    <Container backgroundShadow>
      <div className="p-10 select-none">
        <form onSubmit={_onSubmit}>
          <AuthFormHeader
            application={application}
            title={labels.title}
            description={labels.description}
          />
          <div className="flex flex-col pb-8">
            <Typography
              className="pb-2 text-wallet-light-primary dark:text-wallet-primary"
              variant="h3"
            >
              {labels.label}
            </Typography>
            <Input
              type="email"
              autoComplete="email"
              placeholder="example@email.com"
              errorMessage={errorMessage}
              onChange={onChange}
            />
          </div>
          <div className="flex items-center justify-center pb-4">
            <Button
              type="submit"
              primary
              loading={processing}
              disabled={!email}
            >
              {labels.actionLabel}
            </Button>
          </div>
          <Typography
            variant="caption"
            className="pb-8 text-center text-wallet-light-thirdly dark:text-wallet-thirdly"
            disableSkelton={true}
          >
            <span dangerouslySetInnerHTML={{ __html: labels.policy }}></span>
          </Typography>
          {application?.name ? (
            <div onClick={onShowHint}>
              <Typography
                variant="caption"
                className="pb-12 text-center underline cursor-pointer text-wallet-light-primary dark:text-wallet-primary"
                disableSkelton={true}
              >
                {labels.helpLabel}
              </Typography>
            </div>
          ) : (
            <div className="h-10" />
          )}
          <ReCAPTCHA
            ref={reCaptchaRef}
            size="invisible"
            sitekey={recaptchSiteKey}
          />
        </form>
      </div>
    </Container>
  );
};

type AuthFormHeaderProps = {
  application?: RelatedApplication;
  title: string;
  description: string;
};

const AuthFormHeader: FC<AuthFormHeaderProps> = ({
  application,
  title,
  description,
}) => {
  return (
    <>
      <div className="flex items-center justify-center py-4">
        {application?.iconUrl && (
          <>
            <div className="flex flex-col items-center space-y-1">
              <div className="flex items-center justify-center w-20 h-20 bg-white border rounded-xl">
                <NextImage src={application?.iconUrl} width={48} height={48} />
              </div>
              <Typography variant="caption">{application?.name}</Typography>
            </div>
            <div className="relative flex items-center justify-center w-full h-[80px]">
              <span className="absolute top-[40%] left-0 right-0 border border-dashed" />
              <div className="absolute top-[40%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  className="z-element"
                >
                  <rect width="32" height="32" rx="16" fill="#16B038" />
                  <path
                    d="M8.5 16.75L14.5 22.75L23.5 9.25"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </>
        )}
        <div className="flex flex-col items-center space-y-1">
          <div className="flex items-center justify-center w-20 h-20 bg-white border rounded-xl">
            <AniqueLogo className="w-12 h-12" />
          </div>
          <Typography variant="caption">Anique Wallet</Typography>
        </div>
      </div>
      <div className="pb-10 space-y-4 text-center">
        <Typography
          className="text-wallet-light-primary dark:text-wallet-primary"
          variant="h2"
        >
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </Typography>
        <Typography
          variant="body2"
          className="text-wallet-light-secondary dark:text-wallet-secondary"
        >
          <span dangerouslySetInnerHTML={{ __html: description }} />
        </Typography>
      </div>
    </>
  );
};
