import { cn } from '@collection-platform-frontend/shared';
import { Button, Typography } from '@collection-platform-frontend/ui';
import { FC, ReactElement } from 'react';

export type DropGuardProps = {
  children: ReactElement;
  expired?: boolean;
  soldOut?: boolean;
  needAuth?: boolean;
  primaryStyle?: string;
  onLogin?: () => void;
  onBack?: () => void;
};

export const DropGuard: FC<DropGuardProps> = ({
  children,
  expired,
  soldOut,
  needAuth,
  primaryStyle,
  onLogin,
  onBack,
}) => {
  if (expired || soldOut) {
    return (
      <div className="flex flex-col items-center justify-center">
        <Button primary className={cn('w-full', primaryStyle)} onClick={onBack}>
          トップページへ
        </Button>
      </div>
    );
  }

  if (needAuth) {
    return (
      <div className="flex flex-col items-center">
        <div className="w-full space-y-1">
          <Button
            primary
            className={cn('w-full', primaryStyle)}
            onClick={onLogin}
          >
            ログイン/新規登録
          </Button>
          <Typography variant="caption" className="text-wallet-secondary">
            アイテムの受け取りにはログインが必要です
          </Typography>
        </div>
      </div>
    );
  }

  return children;
};
