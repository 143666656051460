import { Modal, Typography } from '@collection-platform-frontend/ui';
import { FC, PropsWithChildren } from 'react';

export type BaseModalProps = {
  show: boolean;
  onClose?: () => void;
};

export const BaseModal: FC<PropsWithChildren<BaseModalProps>> = ({
  show,
  children,
  onClose,
}) => {
  return (
    <Modal show={show} isShow={onClose} backgroundClassName="bg-black/30">
      <div className="relative px-8 pt-8 pb-4 rounded-3xl bg-white dark:bg-wallet-container dark:sm:shadow-[#111111] w-[508px] max-w-full">
        <div className="flex flex-col items-center">
          {children}

          {onClose ? (
            <div className="flex flex-col items-center mb-1">
              <div className="p-4 cursor-pointer" onClick={onClose}>
                <Typography
                  variant="caption"
                  className="text-wallet-light-secondary"
                >
                  閉じる
                </Typography>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center mb-1">
              <div className="p-4">
                {/*
                 閉じるテキストと同じ大きさのスペースを確保して、
                 状態が切り替わる時にモーダルの動きがガタガタしないようにする
                */}
                <div className="h-3" />
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default BaseModal;
