import {
  customerErrorMessage,
  pickGraphqlError,
  useCustomerProfileUpdateMutation,
} from '@collection-platform-frontend/api';
import {
  Button,
  Image,
  Input,
  Modal,
  Typography,
  useToast,
} from '@collection-platform-frontend/ui';
import { PencilIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CombinedError } from 'urql';

export type Icon = {
  id: string;
  assetUrl: string;
};

type ProfileFormData = {
  customerIconId: string;
  nickName: string;
};

type ProfileFormProps = {
  icons?: Icon[];
  formData: ProfileFormData;
  onUpdate?: (value?: ProfileFormData) => void;
  onError?: (error: CombinedError | undefined) => void;
};

export const ProfileForm: FC<ProfileFormProps> = ({
  icons,
  formData,
  onUpdate,
  onError,
}) => {
  const [showSelector, setShowSelector] = useState<boolean>(false);
  const { success, failure } = useToast();

  const [{ fetching: customerProfileUpdateFetching }, customerProfileUpdate] =
    useCustomerProfileUpdateMutation();

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<ProfileFormData>({
    mode: 'all',
    defaultValues: formData,
  });

  useEffect(() => {
    reset(formData);
  }, [reset, formData]);

  const onSubmitForm = handleSubmit((formData: ProfileFormData) => {
    if (customerProfileUpdateFetching || !formData) {
      return;
    }

    customerProfileUpdate({
      ...formData,
    }).then(({ data, error }) => {
      if (data) {
        success('プロフィール情報を更新しました。');
        onUpdate && onUpdate(formData);
      }

      if (error) {
        const { code, message } = pickGraphqlError(error) ?? {
          code: '',
        };
        failure(customerErrorMessage[code] ?? message);
        onError && onError(error);
      }
    });
  });

  const onEditAvatar = useCallback(() => {
    setShowSelector(true);
  }, []);

  const onClose = useCallback(() => {
    setShowSelector(false);
  }, []);

  const onSelectAvatar = useCallback(
    (icon: Icon) => {
      setValue('customerIconId', icon.id, {
        shouldDirty: true,
      });
    },
    [setValue],
  );

  const onCancel = useCallback(() => {
    reset(formData);
  }, [reset, formData]);

  if (!formData || !icons) {
    return null;
  }

  const selectedIconId = watch('customerIconId');
  const selectedIcon =
    icons?.find(({ id }) => id === selectedIconId) ?? icons?.[0];
  return (
    <>
      <form onSubmit={onSubmitForm}>
        <div className="p-8 shadow-3xl rounded-3xl bg-wallet-light-container dark:bg-wallet-container dark:shadow-[#111111]">
          <Typography className="pb-6" variant="h2">
            プロフィール
          </Typography>
          <div>
            <div
              className="inline-flex flex-col items-center cursor-pointer gap-y-1"
              onClick={onEditAvatar}
            >
              <div className="w-24 h-24 rounded-[50%] pb-2">
                <Image
                  imageClassName="rounded-[50%]"
                  src={selectedIcon.assetUrl}
                  width={96}
                  height={96}
                />
              </div>
              <div className="flex items-center gap-x-1">
                <PencilIcon className="w-4 h-4" />
                <Typography>変更</Typography>
              </div>
            </div>
          </div>
          <div className="flex flex-col pt-4">
            <Typography className="pb-2" variant="h3">
              表示名
            </Typography>
            <Input
              type="text"
              errorMessage={errors.nickName?.message}
              {...register('nickName', {
                required: 'ユーザー名は必須です。',
                // minLength: 4,
                // maxLength: 20,
                validate: (value) => {
                  if (/<|>\|/g.test(value)) {
                    return 'ユーザー名に禁止文字が含まれています。';
                  }

                  if (value.length < 4 || 20 < value.length) {
                    return 'ユーザー名は4-20文字である必要があります。';
                  }

                  return true;
                },
              })}
            />
          </div>
          <div className="flex pt-4 gap-x-4">
            <Button
              type="button"
              visible={isDirty}
              disabled={customerProfileUpdateFetching}
              onClick={onCancel}
            >
              キャンセル
            </Button>
            <Button
              primary
              disabled={!isDirty || !isValid}
              type="submit"
              loading={customerProfileUpdateFetching}
            >
              変更する
            </Button>
          </div>
        </div>
      </form>
      <UserIconChangeModal
        show={showSelector}
        icons={icons}
        selectedIcon={selectedIcon}
        onSelect={onSelectAvatar}
        onClose={onClose}
      />
    </>
  );
};

type UserIconChangeModalProps = {
  show: boolean;
  icons?: Icon[];
  selectedIcon?: Icon;
  onSelect?: (icon: Icon) => void;
  onClose?: () => void;
};

const UserIconChangeModal: FC<UserIconChangeModalProps> = ({
  show,
  icons,
  selectedIcon,
  onSelect,
  onClose,
}) => {
  return (
    <Modal show={show}>
      <div className="relative p-4 rounded-xl bg-wallet-light-container dark:bg-wallet-container">
        <div className="flex flex-col items-center p-4 overflow-y-auto">
          <Typography variant="h3">アイコン変更</Typography>
          <div className="grid grid-cols-3 gap-2 pt-4">
            {icons?.map((icon, i) => {
              const selected = selectedIcon === icon;
              return (
                <div
                  key={i}
                  className="relative w-22 h-22"
                  onClick={() => onSelect && onSelect(icon)}
                >
                  <Image src={icon.assetUrl} width={100} height={100} />
                  {selected && (
                    <CheckCircleIcon className="absolute w-6 h-6 right-1 top-1 dark:text-wallet-light-primary" />
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="sticky bottom-0 px-8 pb-2 left-8 right-8">
          <Button primary onClick={onClose}>
            決定
          </Button>
        </div>
      </div>
    </Modal>
  );
};
