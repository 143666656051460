import {
  NftListItem,
  useCurrentCollection,
  useCurrentCustomer,
} from '@collection-platform-frontend/api';
import { cn } from '@collection-platform-frontend/shared';
import {
  Container,
  IPageLayout,
  Typography,
} from '@collection-platform-frontend/ui';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import { NftItem } from '../../components/collection';

type RenderProps = {
  currentApplicationId?: string;
  useNftFilter?: boolean;
  signupPagePath?: `/${string}`;
  PageLayout: IPageLayout;
  addLayoutProps?: any;
};

export const CollectionPageRender = ({
  currentApplicationId,
  useNftFilter,
  signupPagePath,
  PageLayout,
  addLayoutProps,
}: RenderProps) => {
  const Page = () => {
    const { push, asPath } = useRouter();
    const {
      fetching: customerFething,
      customer,
      authUser,
      registered,
    } = useCurrentCustomer(currentApplicationId, !signupPagePath);
    const myCustomer = customer?.myCustomer;
    const [selectedNftId, setSelectedNftId] = useState<string>();

    const {
      fetching: nftFetching,
      nfts,
      numOfNfts,
      pageInfo,
      observe,
    } = useCurrentCollection(useNftFilter ? currentApplicationId : undefined);

    useEffect(() => {
      if (!signupPagePath || customerFething || registered) {
        return;
      }

      push({
        pathname: signupPagePath,
        query: {
          callbackUrl: asPath,
        },
      });
    }, [customerFething, registered, push, asPath]);

    const onSelectNft = useCallback(
      (nftItem: NftListItem) => () => {
        setSelectedNftId(nftItem.id);
        push(`/collection/${nftItem.id}`);
      },
      [push],
    );

    const children = (
      <Container className="min-h-[640px]" containerClassName="py-4">
        <Typography className="pb-4" variant="h2">
          マイコレクション
        </Typography>
        <div className="relative grid grid-cols-2 gap-y-6 gap-x-2">
          {nfts?.map((nft, i) => {
            const { contractDisplayName, item, id } = nft;
            if (!item) {
              return null;
            }

            const { thumbnailAssetUrl, title } = item;
            const selected = selectedNftId && selectedNftId === id;
            return (
              <div key={i} className={cn('relative', selected && 'opacity-60')}>
                <Link href={`/collection/${id}`} />
                <NftItem
                  image={thumbnailAssetUrl}
                  skeleton={nftFetching || numOfNfts === 0}
                  name={title}
                  contractName={contractDisplayName}
                  onClick={onSelectNft(nft)}
                />
                {selected && (
                  <div className="absolute inset-0 flex flex-col items-center p-10 rounded-3xl gap-y-2 z-element">
                    <div className="flex justify-center">
                      <div className="w-20 h-20 border-4 rounded-full animate-spin border-t-transparent"></div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          {numOfNfts === 0 && (
            <div
              className={cn(
                'absolute top-0 bottom-0 left-0 right-0 transition-opacity opacity-70 duration-500',
                { 'opacity-0': nftFetching },
              )}
            >
              <div className="pt-[200px] text-center text-wallet-light-secondary dark:text-wallet-secondary">
                <Typography>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `おっと、 なにもないようです。</br>あなただけのアイテムを</br>探しにいきましょう。`,
                    }}
                  ></span>
                </Typography>
              </div>
            </div>
          )}
        </div>
        {!nftFetching && pageInfo?.hasNextPage && (
          <div ref={observe} className="flex justify-center w-full py-4" />
        )}
      </Container>
    );

    return (
      <PageLayout authUser={authUser} user={myCustomer} {...addLayoutProps}>
        {children}
      </PageLayout>
    );
  };

  return Page;
};
