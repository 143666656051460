import { FC, SVGAttributes } from 'react';

export const AniqueLogo: FC<SVGAttributes<HTMLOrSVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25 0C11.1932 0 0 11.1932 0 25C0 38.8068 11.1932 50 25 50C38.8068 50 50 38.8068 50 25C50 11.1932 38.8068 0 25 0ZM14.8338 36.6317H10.8297C10.2123 36.6317 9.6642 36.3143 9.35841 35.7835C9.05262 35.2527 9.05262 34.618 9.35841 34.0815L23.8461 8.98915C24.1519 8.45834 24.7057 8.14101 25.3173 8.14101C25.9289 8.14101 26.4828 8.45834 26.7886 8.98915L32.3275 18.5841C32.4025 18.7111 32.4025 18.8553 32.3275 18.988C32.2525 19.1149 32.1255 19.1899 31.9813 19.1899H25.7327C25.3693 19.1899 25.0231 19.3861 24.8442 19.7034L15.1858 36.4355C15.1165 36.5567 14.9781 36.6374 14.8396 36.6374L14.8338 36.6317ZM41.2705 35.7835C40.9647 36.3143 40.4108 36.6317 39.7992 36.6317H35.7951C35.7951 36.6317 35.772 36.6317 35.7547 36.6259C35.622 36.6028 35.5066 36.522 35.4373 36.4066L32.8179 31.8717C32.6275 31.537 32.2698 31.3351 31.8832 31.3351H25.2827C25.1154 31.3351 24.9769 31.2543 24.8904 31.1101C24.8096 30.9658 24.8096 30.8043 24.8904 30.66L27.9598 25.3462C28.0406 25.2077 28.1906 25.1212 28.3522 25.1212H35.8585C36.0201 25.1212 36.1701 25.2077 36.2509 25.3462L41.2762 34.0872C41.582 34.618 41.582 35.2527 41.2762 35.7893L41.2705 35.7835Z"
        fill="#0D0D1A"
      />
    </svg>
  );
};
