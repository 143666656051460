import { cn } from '@collection-platform-frontend/shared';
import { Image, Typography } from '@collection-platform-frontend/ui';
import { FC, HTMLAttributes, useState } from 'react';

export type ItemData = {
  id?: string;
  name?: string;
  contractName?: string;
  image?: string;
};

export type ItemType = 'card' | 'nft' | 'badge';

export type ItemProps = HTMLAttributes<HTMLDivElement> &
  ItemData & {
    skeleton?: boolean;
    type?: ItemType;
    showInfo?: boolean;
  };

const imageClassNames: Record<ItemType, string> = {
  card: 'w-[160px] h-[240px] rounded-lg',
  nft: 'w-[160px] h-[160px] rounded-3xl',
  badge: 'w-[60px] h-[60px] rounded-[50%]',
};

export const Item: FC<ItemProps> = ({
  className,
  image,
  name,
  type = 'nft',
  contractName,
  skeleton,
  showInfo = true,
  onClick,
  ...rest
}) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  const onLoadingComplete = () => {
    setImageLoaded(true);
  };

  const imageClassName = imageClassNames[type];
  const loading = !image || skeleton || !imageLoaded;
  return (
    <div
      className={cn(
        'flex flex-col items-center',
        {
          'cursor-pointer': !!onClick,
        },
        className,
      )}
      onClick={onClick}
      {...rest}
    >
      <Image
        className={cn('overflow-hidden z-base', imageClassName)}
        src={image}
        objectFit="contain"
        layout="fill"
        skeleton={loading}
        onLoadingComplete={onLoadingComplete}
      />
      {name && contractName && (
        <div className="flex flex-col w-full px-3 py-1 space-y-1">
          <Typography
            skeleton={loading}
            variant="h3"
            className="whitespace-pre-line"
          >
            {name}
          </Typography>
          <Typography
            skeleton={loading}
            variant="caption"
            className="whitespace-pre-line text-wallet-light-secondary"
          >
            {contractName}
          </Typography>
        </div>
      )}
    </div>
  );
};

export const NftItem: FC<Omit<ItemProps, 'type'>> = (props) => {
  return <Item type="nft" {...props} />;
};

export const CardItem: FC<Omit<ItemProps, 'type'>> = (props) => {
  return <Item type="card" {...props} />;
};
