import {
  DistributionType,
  isGiftCodeDrop,
  isPurchaseDrop,
} from '@collection-platform-frontend/api';
import { Typography } from '@collection-platform-frontend/ui';
import { FC } from 'react';

export type DropHeaderProps = {
  minted?: boolean;
  expired?: boolean;
  soldOut?: boolean;
  distributionType: DistributionType;
};

export const DropHeader: FC<DropHeaderProps> = ({
  minted,
  expired,
  soldOut,
  distributionType,
}) => {
  return (
    <div className="flex flex-col space-y-1">
      <Typography variant="h2">
        {expired
          ? isPurchaseDrop(distributionType)
            ? '購入期間は終了しました'
            : '受け取り期間は終了しました'
          : soldOut
          ? 'アイテムは売り切れました'
          : minted
          ? 'アイテムを取得しました！'
          : isPurchaseDrop(distributionType)
          ? 'アイテムを購入する'
          : 'アイテムを受け取る'}
      </Typography>
      <Typography variant="caption" className="text-wallet-secondary">
        {expired
          ? isPurchaseDrop(distributionType)
            ? '本アイテムの購入期間は終了しました'
            : '本アイテムの受け取り期間は終了しました'
          : soldOut
          ? '本アイテムの在庫は無くなりました'
          : minted
          ? '本アイテムはコレクションに追加されました'
          : isGiftCodeDrop(distributionType)
          ? 'シリアルコードを入力して、特別なアイテムを手に入れましょう'
          : '特別なアイテムを手に入れましょう'}
      </Typography>
    </div>
  );
};
