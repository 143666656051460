import { cn } from '@collection-platform-frontend/shared';
import { Button, Typography } from '@collection-platform-frontend/ui';
import { motion } from 'framer-motion';
import { FC } from 'react';

export type DropConfirmProps = {
  showResult?: boolean;
  errorMessage?: string;
  isCodeNeeded?: boolean;
  onConfirm?: () => void;
  onReset?: () => void;
};

export const DropConfirm: FC<DropConfirmProps> = ({
  showResult,
  errorMessage,
  isCodeNeeded,
  onConfirm,
  onReset,
}) => {
  return (
    <motion.div
      className="flex-col items-center justify-center hidden"
      animate={
        showResult
          ? {
              opacity: 1,
              display: 'flex',
            }
          : {
              opacity: 0,
              display: 'none',
            }
      }
      transition={{
        duration: 0.7,
        delay: 0.5,
      }}
    >
      {errorMessage && (
        <Typography
          variant="caption"
          className={cn('py-2 text-wallet-light-error')}
        >
          {errorMessage}
        </Typography>
      )}
      <Button
        className={cn({ 'mt-6': !errorMessage && showResult })}
        primary
        disabled={!showResult}
        onClick={onConfirm}
      >
        <div className="flex items-center justify-center space-x-1">
          <span>アイテムを確認する</span>
        </div>
      </Button>
      <Typography
        variant="caption"
        className="py-2 text-center text-wallet-thirdly"
        markdown
      >
        {'受け取り済みのアイテムは、</br>マイコレクションから確認ができます'}
      </Typography>
      {isCodeNeeded && (
        <div className="py-4 cursor-pointer" onClick={onReset}>
          <Typography className="underline">
            もう一度コードを入力する
          </Typography>
        </div>
      )}
    </motion.div>
  );
};
