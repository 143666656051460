import { FC, SVGAttributes } from 'react';

export const PointIcon: FC<SVGAttributes<Element>> = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_ii_4547_2152)">
        <circle cx="6.5" cy="7" r="6.5" fill="#F9CC2E" />
      </g>
      <g filter="url(#filter1_ii_4547_2152)">
        <circle cx="6.50005" cy="7.00005" r="5.2" fill="#F9CC2E" />
      </g>
      <g filter="url(#filter2_dii_4547_2152)">
        <path
          d="M6.11963 2.97087C6.23937 2.60234 6.76073 2.60234 6.88047 2.97087L7.57772 5.11677C7.63127 5.28158 7.78485 5.39316 7.95814 5.39316H10.2145C10.602 5.39316 10.7631 5.88901 10.4496 6.11677L8.62417 7.44301C8.48398 7.54486 8.42531 7.72541 8.47886 7.89022L9.17611 10.0361C9.29585 10.4046 8.87406 10.7111 8.56057 10.4833L6.73516 9.15709C6.59497 9.05523 6.40513 9.05523 6.26493 9.15709L4.43952 10.4833C4.12604 10.7111 3.70425 10.4046 3.82399 10.0361L4.52123 7.89022C4.57478 7.72541 4.51612 7.54486 4.37592 7.44301L2.55051 6.11677C2.23703 5.88901 2.39814 5.39316 2.78563 5.39316H5.04196C5.21525 5.39316 5.36883 5.28158 5.42238 5.11677L6.11963 2.97087Z"
          fill="#FCE836"
        />
      </g>
      <defs>
        <filter
          id="filter0_ii_4547_2152"
          x="-2"
          y="-1.5"
          width="17"
          height="17"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.770833 0 0 0 0 0.504896 0 0 0 0 0.192708 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_4547_2152"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_4547_2152"
            result="effect2_innerShadow_4547_2152"
          />
        </filter>
        <filter
          id="filter1_ii_4547_2152"
          x="-0.699951"
          y="-0.199951"
          width="14.4"
          height="14.4"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.770833 0 0 0 0 0.504896 0 0 0 0 0.192708 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_4547_2152"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.945833 0 0 0 0 0.941892 0 0 0 0 0.847309 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_4547_2152"
            result="effect2_innerShadow_4547_2152"
          />
        </filter>
        <filter
          id="filter2_dii_4547_2152"
          x="0.384888"
          y="0.694458"
          width="13.2303"
          height="12.8671"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.720833 0 0 0 0 0.508188 0 0 0 0 0.189219 0 0 0 0.6 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4547_2152"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4547_2152"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.633333 0 0 0 0 0.488511 0 0 0 0 0.116111 0 0 0 1 0"
          />
          <feBlend
            mode="color-burn"
            in2="shape"
            result="effect2_innerShadow_4547_2152"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.997814 0 0 0 0 0.933333 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_innerShadow_4547_2152"
            result="effect3_innerShadow_4547_2152"
          />
        </filter>
      </defs>
    </svg>
  );
};
