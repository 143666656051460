import { useAuthUser } from 'next-firebase-auth';
import { useEffect } from 'react';

export const SignoutPageRender = () => {
  const SignOutPage = () => {
    const authUser = useAuthUser();

    useEffect(() => {
      authUser.signOut();
    }, [authUser]);

    return null;
  };

  return SignOutPage;
};
