import { FC, SVGAttributes } from 'react';

export const CompleteIcon: FC<SVGAttributes<Element>> = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 82 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_dd_403_3268)">
        <circle cx="41" cy="37" r="37" fill="#FACC15" />
      </g>
      <g filter="url(#filter1_dd_403_3268)">
        <path
          d="M36.7855 44.5233L35.2188 50.0078L33.652 44.5233C33.2471 43.1067 32.4879 41.8165 31.446 40.7747C30.4042 39.7328 29.114 38.9736 27.6973 38.5686L22.2109 37L27.6954 35.4333C29.1121 35.0283 30.4022 34.2691 31.4441 33.2273C32.486 32.1854 33.2452 30.8953 33.6501 29.4786L35.2188 23.9922L36.7855 29.4767C37.1904 30.8933 37.9496 32.1835 38.9915 33.2253C40.0333 34.2672 41.3235 35.0264 42.7402 35.4314L48.2266 37L42.7421 38.5667C41.3254 38.9717 40.0353 39.7309 38.9934 40.7727C37.9515 41.8146 37.1923 43.1047 36.7874 44.5214L36.7855 44.5233ZM53.0616 30.6695L52.5625 32.6641L52.0634 30.6695C51.7777 29.526 51.1866 28.4816 50.3533 27.6479C49.52 26.8143 48.4758 26.2228 47.3324 25.9366L45.3359 25.4375L47.3324 24.9384C48.4758 24.6522 49.52 24.0607 50.3533 23.2271C51.1866 22.3934 51.7777 21.349 52.0634 20.2055L52.5625 18.2109L53.0616 20.2055C53.3475 21.3493 53.9388 22.3938 54.7725 23.2275C55.6062 24.0612 56.6507 24.6525 57.7945 24.9384L59.7891 25.4375L57.7945 25.9366C56.6507 26.2225 55.6062 26.8138 54.7725 27.6475C53.9388 28.4812 53.3475 29.5257 53.0616 30.6695ZM50.4311 53.5093L49.6719 55.7891L48.9126 53.5093C48.6997 52.8707 48.3411 52.2905 47.8652 51.8145C47.3892 51.3386 46.8089 50.9799 46.1704 50.7671L43.8906 50.0078L46.1704 49.2485C46.8089 49.0357 47.3892 48.6771 47.8652 48.2011C48.3411 47.7251 48.6997 47.1449 48.9126 46.5063L49.6719 44.2266L50.4311 46.5063C50.644 47.1449 51.0026 47.7251 51.4786 48.2011C51.9546 48.6771 52.5348 49.0357 53.1734 49.2485L55.4531 50.0078L53.1734 50.7671C52.5348 50.9799 51.9546 51.3386 51.4786 51.8145C51.0026 52.2905 50.644 52.8707 50.4311 53.5093Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
