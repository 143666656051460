import {
  Button,
  IPageLayout,
  Typography,
} from '@collection-platform-frontend/ui';
import { useRouter } from 'next/router';

type NotFoundPageRenderProps = {
  PageLayout: IPageLayout;
  onHome?: () => void;
};

export const NotFoundPageRender = ({
  PageLayout,
  onHome,
}: NotFoundPageRenderProps) => {
  const Page = () => {
    const { push } = useRouter();
    const onBack = () => {
      if (onHome) {
        onHome();
      } else {
        push('/');
      }
    };

    return (
      <PageLayout>
        <div className="flex flex-col items-center justify-center py-16 mx-auto sm:py-48">
          <Typography variant="h2" className="mb-8 text-9xl">
            404
          </Typography>
          <Typography variant="h3" className="px-4 mb-8">
            <span>ページが見つかりませんでした</span>
          </Typography>
          <Button primary className="w-[240px]" onClick={onBack}>
            トップへ戻る
          </Button>
        </div>
      </PageLayout>
    );
  };

  return Page;
};
