import {
  storeAuthToken,
  useIsExistCustomerQuery,
} from '@collection-platform-frontend/api';
import { FirebaseError } from '@firebase/util';
import { Router, useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import {
  AuthCallback,
  InvalidLink,
  ValidateResult,
} from '../../components/auth';

type AuthCallbackPageRouter = Router & {
  query: {
    callbackUrl: string;
  };
};

type RenderProps = {
  currentApplicationId?: string;
  signupPagePath?: string;
  onBack?: () => void;
};

export const AuthCallbackPageRender = ({
  currentApplicationId,
  signupPagePath,
  onBack,
}: RenderProps = {}) => {
  const AuthCallbackPage = () => {
    const { query, isReady, push } = useRouter() as AuthCallbackPageRouter;
    const [authError, setAuthError] = useState<FirebaseError>();
    const [idToken, setIdToken] = useState<string>();

    const backToHome = useMemo(() => {
      return onBack ?? (() => push('/'));
    }, [push]);

    const [{ data, error, fetching }] = useIsExistCustomerQuery({
      pause: !isReady || !signupPagePath || !idToken,
      requestPolicy: 'network-only',
      variables: {
        applicationId: currentApplicationId,
      },
    });
    const isExistCustomer = data?.existCustomer.exist;

    useEffect(() => {
      if (!idToken || fetching) {
        return;
      }

      if (signupPagePath && !isExistCustomer) {
        const url = new URL(signupPagePath, window.location.href);
        if (query.callbackUrl) {
          url.searchParams.set('callbackUrl', query.callbackUrl);
        }

        push(url.toString());
        return;
      }

      if (query.callbackUrl) {
        push(query.callbackUrl);
      } else {
        backToHome();
      }
    }, [query, fetching, push, isExistCustomer, error, idToken, backToHome]);

    const onValidate = async ({ error, credential }: ValidateResult) => {
      if (error) {
        setAuthError(error);
      }

      if (credential) {
        const idToken = await credential.user.getIdToken();
        setIdToken(idToken);
        storeAuthToken(idToken);
      }
    };

    if (authError) {
      return <InvalidLink onBackTo={backToHome} />;
    }

    return <AuthCallback onValidate={onValidate} />;
  };

  return AuthCallbackPage;
};
