import { cn } from '@collection-platform-frontend/shared';
import { Image, Typography } from '@collection-platform-frontend/ui';
import { motion } from 'framer-motion';
import { FC } from 'react';

import { DropOperation } from './action';

type DropItemProps = {
  minted?: boolean;
  drop?: DropOperation;
  dropItems?: DropOperation[];
  dropOperation?: DropOperation[];
  initCode?: string[];
};

export const DropItem: FC<DropItemProps> = ({
  minted,
  drop,
  dropItems,
  dropOperation,
  initCode,
}) => {
  const animate = (toShow: boolean) =>
    minted
      ? {
          rotateY: 720,
          scale: 1.1,
          opacity: toShow ? 0 : 1,
          translateY: 20,
        }
      : {
          rotateY: 0,
          scale: 1,
          opacity: toShow ? 1 : 0,
        };

  return (
    <div className="relative flex flex-col items-center justify-center space-y-2">
      {initCode && initCode.length >= 2 && (
        <motion.div
          animate={
            minted
              ? {
                  opacity: 0,
                }
              : {
                  opacity: 1,
                }
          }
          className="absolute -top-2 right-2 border-4 border-white bg-wallet-light-primary rounded-full w-10 h-10 flex justify-center items-center z-element"
        >
          <Typography className="text-white">
            <span className="text-xs">x </span>
            {initCode.length}
          </Typography>
        </motion.div>
      )}
      <div className="relative w-full min-h-[250px]">
        {drop?.image && (
          <motion.div
            animate={animate(true)}
            transition={{
              duration: 0.8,
              delay: 0.1,
            }}
            className="absolute inset-0"
          >
            <Image
              className="w-[240px] h-[240px] mx-auto"
              imageClassName="rounded-2xl"
              layout="fill"
              priority={true}
              src={drop.image}
            />
          </motion.div>
        )}
        <div
          className={cn('grid w-[240px] gap-4 mx-auto', {
            'grid-cols-1': dropItems?.length === 1,
            'grid-cols-2': dropItems && dropItems?.length >= 2,
          })}
        >
          {dropItems?.map(({ image }, i) => {
            if (
              dropItems.length > 1 &&
              dropOperation &&
              dropOperation?.[0].image !== image
            ) {
              return null;
            }

            return (
              <motion.div
                key={i}
                animate={animate(false)}
                transition={{
                  duration: 0.8,
                  delay: 0.1,
                }}
              >
                <Image
                  className={cn('aspect-square', {
                    'w-[240px]': dropItems?.length === 1,
                    'w-[100px]': dropItems?.length >= 2,
                  })}
                  imageClassName="rounded-2xl"
                  layout="fill"
                  priority={true}
                  src={image}
                />
              </motion.div>
            );
          })}
        </div>
      </div>

      <motion.div
        animate={
          minted
            ? {
                translateY: 36,
              }
            : {
                translateY: 0,
              }
        }
        transition={{
          duration: 0.8,
          delay: 0.1,
        }}
      >
        <motion.div
          animate={
            dropItems && dropItems?.length >= 2 && minted
              ? {
                  opacity: 0,
                }
              : {
                  opacity: 1,
                }
          }
        >
          <Typography className="max-w-[270px]" variant="h3">
            {dropOperation ? dropOperation?.[0].name : drop?.name}
          </Typography>
        </motion.div>
      </motion.div>
    </div>
  );
};
