import { cn, format, to } from '@collection-platform-frontend/shared';
import { Button, Typography } from '@collection-platform-frontend/ui';
import { CheckIcon } from '@heroicons/react/24/outline';
import { LockClosedIcon } from '@heroicons/react/24/solid';
import { FC, ReactNode } from 'react';

import { PointColumn } from './point-column';

type MissionItemFrameProps = {
  className?: string;
  content: ReactNode;
  action: ReactNode;
};

type MissionItemTitleProps = { className?: string; children: ReactNode };

type MissionItemRewardProps = {
  className?: string;
  amount?: number | null;
  unit: string;
};

type MissionItemDescriptionProps = {
  children?: ReactNode;
};

type UnrevealedMissionItemProps = {
  className?: string;
  startAt: Date;
};

type InProgressMissionItemProps = {
  id: string;
  className?: string;
  title?: string | null;
  pointAmount?: number | null;
  pointUnit: string;
  current?: Date;
  endAt: Date;
  onAction: (id: string) => void;
};

type AchievedMissionItemProps = {
  className?: string;
  title?: string | null;
  pointAmount?: number | null;
  pointUnit: string;
};

type ExpiredMissionItemProps = {
  className?: string;
  title?: string | null;
  pointAmount?: number | null;
  pointUnit: string;
};

type MissionItemProps = {
  isRevealed: boolean;
  isAchieved: boolean;
  isExpiredCampaign: boolean;
} & UnrevealedMissionItemProps &
  InProgressMissionItemProps &
  AchievedMissionItemProps &
  ExpiredMissionItemProps;

const MissionItemFrame: FC<MissionItemFrameProps> = ({
  className,
  content,
  action,
}) => (
  <div
    className={cn(
      'p-8 pl-6 pr-4 border border-black dark:border-white rounded-xl shadow-lg w-full text-left',
      className,
    )}
  >
    <div className="flex flex-row items-start justify-between gap-x-4">
      <div className="flex flex-col">{content}</div>

      <div className="flex flex-col self-center">{action}</div>
    </div>
  </div>
);

const MissionItemTitle: FC<MissionItemTitleProps> = ({
  className,
  children,
}) => (
  <Typography variant="body" className={cn('text-cloudear-blue', className)}>
    {children}
  </Typography>
);

const MissionItemReward: FC<MissionItemRewardProps> = ({
  className,
  amount,
  unit,
}) => {
  return (
    <div
      className={cn(
        'flex flex-row justify-start items-center flex-wrap gap-y-1',
        className,
      )}
    >
      <Typography variant="body" className="whitespace-nowrap">
        報酬：
      </Typography>
      {amount && <PointColumn amount={amount} unit={unit} />}
    </div>
  );
};

const MissionItemDescription: FC<MissionItemDescriptionProps> = ({
  children,
}) => (
  <Typography variant="body2" className="text-wallet-light-secondary">
    {children}
  </Typography>
);

export const UnrevealedMissionItem: FC<UnrevealedMissionItemProps> = ({
  className,
  startAt,
}) => (
  <MissionItemFrame
    className={className}
    content={
      <div>
        <MissionItemTitle className="mb-2">Coming Soon</MissionItemTitle>

        <MissionItemDescription>
          {format(startAt, 'M月D日')}に公開予定
        </MissionItemDescription>
      </div>
    }
    action={
      <Button className="w-[120px]" primary slim disabled>
        <div className="flex flex-row items-center justify-center">
          <LockClosedIcon className="w-5 h-5" />
        </div>
      </Button>
    }
  />
);

export const InProgressMissionItem: FC<InProgressMissionItemProps> = ({
  id,
  className,
  title,
  pointAmount,
  pointUnit,
  current,
  endAt,
  onAction,
}) => {
  const leftTime = to(endAt, current, { locale: 'ja', withoutSuffix: true });

  return (
    <MissionItemFrame
      className={className}
      content={
        <div>
          <MissionItemTitle className="mb-1">{title}</MissionItemTitle>

          <MissionItemReward
            className="mb-2"
            amount={pointAmount}
            unit={pointUnit}
          />

          <MissionItemDescription>期間： あと{leftTime}</MissionItemDescription>
        </div>
      }
      action={
        <Button className="w-[120px]" slim onClick={() => onAction(id)}>
          挑戦する
        </Button>
      }
    />
  );
};

export const AchievedMissionItem: FC<AchievedMissionItemProps> = ({
  className,
  title,
  pointAmount,
  pointUnit,
}) => (
  <MissionItemFrame
    className={className}
    content={
      <div>
        <MissionItemTitle className="mb-2">{title}</MissionItemTitle>

        <MissionItemReward amount={pointAmount} unit={pointUnit} />
      </div>
    }
    action={
      <div className="flex flex-row items-center p-4 text-green-500 gap-x-1">
        <Typography variant="body" className="whitespace-nowrap">
          達成済み
        </Typography>
        <CheckIcon className="w-5 h-5" />
      </div>
    }
  />
);

export const ExpiredMissionItem: FC<ExpiredMissionItemProps> = ({
  className,
  title,
  pointAmount,
  pointUnit,
}) => (
  <MissionItemFrame
    className={cn(className, 'opacity-50')}
    content={
      <div>
        <MissionItemTitle className="mb-2">{title}</MissionItemTitle>

        <MissionItemReward amount={pointAmount} unit={pointUnit} />
      </div>
    }
    action={
      <div className="flex flex-row items-center p-4 text-red-500 gap-x-1">
        <Typography variant="body" className="whitespace-nowrap">
          期間終了
        </Typography>
      </div>
    }
  />
);

export const MissionItem: FC<MissionItemProps> = ({
  id,
  className,
  startAt,
  endAt,
  title,
  pointAmount,
  pointUnit,
  isExpiredCampaign,
  isRevealed,
  isAchieved,
  onAction,
}) => {
  const now = new Date();
  const state = !isRevealed
    ? 'before-start'
    : isAchieved
    ? 'achieved'
    : !isExpiredCampaign && startAt <= now && now < endAt
    ? 'in-progress'
    : 'expired';

  if (state === 'before-start') {
    return <UnrevealedMissionItem className={className} startAt={startAt} />;
  }

  return state === 'achieved' ? (
    <AchievedMissionItem
      className={className}
      title={title}
      pointAmount={pointAmount}
      pointUnit={pointUnit}
    />
  ) : state === 'in-progress' ? (
    <InProgressMissionItem
      id={id}
      className={className}
      title={title}
      pointAmount={pointAmount}
      pointUnit={pointUnit}
      current={now}
      endAt={endAt}
      onAction={onAction}
    />
  ) : (
    <ExpiredMissionItem
      className={className}
      title={title}
      pointAmount={pointAmount}
      pointUnit={pointUnit}
    />
  );
};
