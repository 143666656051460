import { usePingQuery } from '@collection-platform-frontend/api';
import { useInterval } from '@collection-platform-frontend/shared';
import { Typography } from '@collection-platform-frontend/ui';
import { ReactNode, useEffect } from 'react';

type RenderOption = {
  appIcon: ReactNode;
  refetchInterval?: number;
  onActive: () => void;
};

export const MaintenancePageRender = ({
  appIcon,
  refetchInterval = 10000,
  onActive,
}: RenderOption) => {
  const MaintenancePage = () => {
    const [{ fetching, data }, refetch] = usePingQuery({
      requestPolicy: 'cache-and-network',
      pause: true,
    });

    useInterval(() => {
      if (!fetching && !data) {
        refetch();
      }
    }, refetchInterval);

    useEffect(() => {
      if (!data) {
        return;
      }

      onActive();
    }, [data]);

    return (
      <div className="flex items-center justify-center w-screen h-screen text-wallet-light-primary dark:text-wallet-primary">
        <div>
          {appIcon}
          <Typography variant="h1">メンテナンス中です</Typography>
          <div className="flex flex-col py-6 text-wallet-light-secondary dark:text-wallet-secondary">
            <Typography variant="h3">
              <span
                dangerouslySetInnerHTML={{
                  __html: `ただいまメンテナンス中です。<br />メンテナンス完了までしばらくお待ちください。`,
                }}
              />
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  return MaintenancePage;
};
