import {
  Button,
  Container,
  Typography,
} from '@collection-platform-frontend/ui';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useRouter } from 'next/router';
import { FC } from 'react';

type InvalidLinkProps =
  | {
      backUrl: string;
      onBackTo?: () => void;
    }
  | {
      backUrl?: string;
      onBackTo: () => void;
    };

export const InvalidLink: FC<InvalidLinkProps> = ({ backUrl, onBackTo }) => {
  const router = useRouter();

  const onBack = () => {
    if (backUrl) {
      router.push(backUrl);
    }

    if (onBackTo) {
      onBackTo();
    }
  };

  return (
    <Container>
      <div className="flex flex-col items-center justify-center pt-20 text-wallet-light-primary dark:text-wallet-primary">
        <ExclamationCircleIcon className="w-40 h-40 mb-4" />
        <div className="text-center">
          <Typography variant="h2">このリンクは無効になりました。</Typography>
          <div className="py-6">
            <Typography className="text-wallet-light-secondary dark:text-wallet-secondary">
              もう一度始めからやり直してください。
            </Typography>
          </div>
          <Button primary onClick={onBack}>
            トップにもどる
          </Button>
        </div>
      </div>
    </Container>
  );
};
