import { Button, Typography } from '@collection-platform-frontend/ui';
import { FC } from 'react';

import BaseModal, { BaseModalProps } from './base';

type Props = BaseModalProps & {
  onSignIn: () => void;
};

export const LoginModal: FC<Props> = ({ show, onClose, onSignIn }) => {
  return (
    <BaseModal show={show} onClose={onClose}>
      <Typography variant="h2" className="mb-2">
        ログイン/新規登録が必要です。
      </Typography>
      <Typography variant="body2" className="text-wallet-light-secondary">
        キャンペーンに参加するにはアカウント登録が必要です。
      </Typography>
      <Button
        type="submit"
        className="mt-6 mb-2 w-[240px]"
        primary
        onClick={onSignIn}
      >
        ログイン/新規登録
      </Button>
    </BaseModal>
  );
};
