import {
  cn,
  format,
  useAverageColorFromImageUrl,
} from '@collection-platform-frontend/shared';
import { Typography } from '@collection-platform-frontend/ui';

import { Badge } from './badge';
import { PointColumn } from './point-column';

const BACKGROUND_FALLBACK_COLOR = '#3F52D833';
const BACKGROUND_UNRECEIVED_COLOR = '#D3D3D3';

export type BadgeCardProps = {
  id: string;
  className?: string;
  title: string;
  description: string;
  image?: string | null;
  pointAmount: number;
  pointUnit: string;
  achievedAt?: Date | null;
};

export const BadgeCard: React.FC<BadgeCardProps> = ({
  id,
  className,
  title,
  description,
  image,
  pointAmount,
  pointUnit,
  achievedAt,
}: BadgeCardProps) => {
  const isAchieved = !!achievedAt;

  const { color, analysis } = useAverageColorFromImageUrl(id, image, {
    ignoreWhite: true,
    ignoreBlack: true,
  });

  if (!analysis) {
    return null;
  }

  return (
    <div
      className={cn(
        'flex flex-col rounded-3xl overflow-hidden shadow-xl dark:bg-wallet-container',
        className,
      )}
    >
      <div
        className={cn(
          'flex flex-col justify-center items-center w-full h-[104px]',
        )}
        style={{
          background: isAchieved
            ? color?.rgba ?? BACKGROUND_FALLBACK_COLOR
            : BACKGROUND_UNRECEIVED_COLOR,
        }}
      >
        <Badge image={image} grayscale={!isAchieved} />
      </div>
      <div className="flex flex-col justify-between flex-1 px-8 pt-3 pb-4 text-start dark:text-wallet-primary text-wallet-light-primary">
        <div className="flex flex-col">
          {!isAchieved && (
            <Typography
              variant="caption"
              className="leading-4 text-wallet-light-secondary"
            >
              未取得
            </Typography>
          )}
          <Typography variant="h3">{title}</Typography>
          <Typography variant="body2" className="mb-4">
            {description}
          </Typography>
        </div>

        {isAchieved ? (
          <Typography
            variant="caption"
            className="leading-4 text-wallet-light-secondary"
          >
            取得日：{format(achievedAt, 'YYYY年M月D日')}
          </Typography>
        ) : (
          <div className="flex flex-row items-center gap-x-1">
            <Typography
              variant="caption"
              className="text-wallet-light-secondary"
            >
              必要ポイント：
            </Typography>
            <PointColumn amount={pointAmount} unit={pointUnit} />
          </div>
        )}
      </div>
    </div>
  );
};
