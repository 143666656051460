import {
  customerErrorMessage,
  pickGraphqlError,
  useRequestChangeEmailMutation,
} from '@collection-platform-frontend/api';
import {
  Button,
  ConfirmModal,
  Input,
  Typography,
  useToast,
} from '@collection-platform-frontend/ui';
import { Square2StackIcon } from '@heroicons/react/24/outline';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CombinedError } from 'urql';
import isEmail from 'validator/lib/isEmail';

type AccountFormData = {
  id: string;
  email: string;
};

type AccountFormProps = {
  formData: AccountFormData;
  onUpdate?: (value?: AccountFormData) => void;
  onError?: (error: CombinedError | undefined) => void;
};

export const AccountForm: FC<AccountFormProps> = ({
  formData,
  onUpdate,
  onError,
}) => {
  const [{ fetching: requestChangeEmailFetching }, requestChangeEmail] =
    useRequestChangeEmailMutation();

  const { failure } = useToast();

  const [showConfirm, isShowConfirm] = useState<boolean>(false);
  const [newEmailAddress, setNewEmailAddress] = useState<string>();

  const onChangeAddress = useCallback(() => {
    isShowConfirm(false);
  }, []);

  const confirmLabels = useMemo(() => {
    return {
      title: 'メールの確認',
      message: `変更用メールアドレス</br>（${newEmailAddress}）へ</br>認証メールを送信しました。`,
      resetLabel: 'メールアドレスを訂正する',
    };
  }, [newEmailAddress]);

  const { success } = useToast();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<AccountFormData>({
    mode: 'all',
    defaultValues: formData,
  });

  useEffect(() => {
    reset(formData);
  }, [reset, formData]);

  const onSubmitForm = handleSubmit((formData: AccountFormData) => {
    if (requestChangeEmailFetching || !formData) {
      return;
    }

    const url = new URL('/setting/email/verify', window.location.origin);
    requestChangeEmail({
      newEmail: formData.email,
      callbackUrl: url.toString(),
    }).then(({ data, error }) => {
      if (data) {
        onUpdate && onUpdate(formData);
        setNewEmailAddress(formData.email);
        isShowConfirm(true);
      }

      if (error) {
        const { code, message } = pickGraphqlError(error) ?? {
          code: '',
        };
        failure(customerErrorMessage[code] ?? message);
        onError && onError(error);
      }
    });
  });

  const onCopy = useCallback(() => {
    if (!formData.id) {
      return;
    }

    navigator.clipboard.writeText(formData.id).then(() => {
      success('ユーザーIDをコピーしました。');
    });
  }, [formData, success]);

  const onCancel = useCallback(() => {
    reset(formData);
  }, [reset, formData]);

  if (!formData) {
    return null;
  }

  return (
    <>
      <form onSubmit={onSubmitForm}>
        <div className="p-8 shadow-3xl rounded-3xl bg-wallet-light-container dark:bg-wallet-container dark:shadow-[#111111]">
          <Typography className="pb-6" variant="h2">
            アカウント
          </Typography>
          <Typography className="pb-2" variant="h3">
            ユーザーID
          </Typography>
          <div className="relative w-full pb-4 cursor-pointer" onClick={onCopy}>
            <Typography className="truncate max-w-[calc(95%)]">
              {formData?.id}
            </Typography>
            <Square2StackIcon className="absolute right-0 w-4 h-4 top-1 min-w-4" />
          </div>
          <Typography className="pb-2" variant="h3">
            メールアドレス
          </Typography>
          <Input
            type="email"
            {...register('email', {
              required: 'メールアドレスは必須です。',
              validate: (value) => {
                return isEmail(value)
                  ? true
                  : '正しいメールアドレスの形式で入力してください。';
              },
            })}
            errorMessage={errors.email?.message}
          />
          <div className="flex pt-4 gap-x-4">
            <Button
              type="button"
              visible={isDirty}
              disabled={requestChangeEmailFetching}
              onClick={onCancel}
            >
              キャンセル
            </Button>
            <Button
              primary
              disabled={!isDirty || !isValid}
              type="submit"
              loading={requestChangeEmailFetching}
            >
              変更する
            </Button>
          </div>
        </div>
      </form>
      <ConfirmModal
        show={showConfirm}
        labels={confirmLabels}
        onReset={onChangeAddress}
      />
    </>
  );
};
